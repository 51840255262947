import React from "react";
import styled from "styled-components";
import { corianderGreen, pomegranateRed } from "../Styles/colors";
import { Title } from "../Styles/title";
import Config from '../config/Config';

const NavbarStyled = styled.div`
  background-color: ${corianderGreen};
  padding: 6px 10px;
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;  
`;

const Logo = styled(Title)`
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserStatus = styled.div`
  color: white; 
  font-size: 20px; 
  margin-right: 30px; 
`
//  font-size: 25px; 
const CartStatus = styled.div`
  color: white; 
  margin-right: 20px; 
  letter-spacing: 0px;
  display: inline-block;
  margin-top: 2px;
`

//font-size: 25px; 
const CartStatusWithoutSpacing = styled.div`
  color: white; 
  margin-right: 30px;
  display: inline-block;
`
//  font-size: 25px; 
/* Multi line example
  ${({ helpPlease }) =>
     helpPlease == true
      ? `
    color: ${pomegranateRed};
    text-shadow: 1px 1px 4px #380502;
  `
*/
const Help = styled.div`
  background-color: rgba(241, 196, 15, 0.5);
  border-radius : 10px;
  padding : 4px;
  font-size: 20px;
  font-weight: bold;
  color: white; 
  margin-right: 15px; 
  display: inline-block;
  ${({ helpPlease }) =>
     helpPlease == true
      ? `
    color: #f9ca24;
  `
      : `
      color: #ffffff;
  `}
`

const HelpWithImg = styled.div`
  background-color: transparent;
  border-radius : 1rem;
  border: none;
  margin-right: 15px; 
  display: inline-block;
`
// ffeaa7

const LoginButton = styled.span`
  cursor: pointer; 
`

// https://www.copypastecharacter.com/numerals
const numberDict = {
  0: '⚪',
  1: '➊',
  2: '➋',
  3: '➌',
  4: '➍',
  5: '➎',
  6: '➏',
  7: '➐',
  8: '➑',
  9: '➒',
  10: '➓',
  11: '⓫',
  12: '⓬',
  13: '⓭',
  14: '⓮',
  15: '⓯',
  16: '⓰',
  17: '⓱',
  18: '⓲',
  19: '⓳',
  20: '⓴'
};



export function Navbar({login, loggedIn, logout, orders, setOrders, toggleShowOrders, restauTableKey, restauName, setOpenHelpDialog, disableHelp, helpPlease, setShowOrders, setOpenFood, restauId}) {

  function closeAllDialogs(){
    setShowOrders(false);
    setOpenHelpDialog(false);
    setOpenFood(null);
  }
  var draftOrders = 0;
  for (const key in orders) {
    if (orders[key].status === "DRAFT") {
      draftOrders += 1;
    }
  }

  return (
    <NavbarStyled>
      <Logo onClick={closeAllDialogs}>
      {Config.environment}{restauName}{" "}
        {/* <span role="img" aria-label="pizza slice">
          🍕
        </span> */}
      </Logo>
      <div style={{alignItems : "center", display: "flex"}}>
        {disableHelp == false && restauName != "None" &&  
            // (<Help helpPlease={helpPlease} onClick={()=>{setOpenHelpDialog(true)}}>
            //   <b>Help</b>
            // </Help>)
            (<HelpWithImg onClick={()=>{setOpenHelpDialog(true)}}>
              {helpPlease === true? 
                  <img style={{width: "4em"}} src="/call-waiter-on2.png"/> :
                  <img style={{width: "4em"}} src="/call-waiter-off2.png"/> }
            </HelpWithImg>)
        }
        <CartStatus onClick={toggleShowOrders} >
          <span style={{color: "#FFFFFF"}}> <i className="fas fa-cart-plus"></i> </span> 
          {/* <span role="img" aria-label="Cart" style={{"fontWeight": "bold"}}> {Object.keys(orders).length}  </span> */}
          <span role="img" aria-label="Cart" style={{"fontWeight": "bold"}}> {draftOrders > 0 ? draftOrders : <>&nbsp;</>}  </span>
        </CartStatus> 
      </div>
         
        {/* {
          <CartStatus>
              🍽️  👨‍🍳 {loggedIn !== "loading" ? (
          <>
            👤 {loggedIn ? "Logged in." : ""}
            {loggedIn ? (
              <LoginButton onClick={logout}> Log out </LoginButton>
            ) : (
              <LoginButton onClick={login}> Log in / Sign up </LoginButton>
            )}
          </>
        ) : (
          "loading..."
        )} 
        </CartStatus>}
       */}
    </NavbarStyled>
  );
}
