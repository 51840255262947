import React from "react";
import styled from "styled-components";
//import { foods } from "../Data/FoodData";
import { formatPrice } from "../Data/FoodData";
import Collapsible from "./Collapsible"
import {
  DialogContent
} from "../FoodDialog/FoodDialog";
//import Collapsible from 'react-collapsible';

//  margin: 0px 400px 50px 20px;
//  height: 1000px;
export const MenuStyled = styled.div`
margin: 50px 100px 100px 100px;
  // Add this part
  @media(max-width: 1000px){ 
    margin: 50px 50px 100px 50px;
  }
  @media(max-width: 720px){ 
    width: 100%; 
    margin: auto; 
    margin-bottom: 100px;
    margin-top: 50px;

  }
`;

const MenuContent = styled(DialogContent)`
  padding: 20px;
  height: 100%;
`;

export const FoodGrid = styled.div`
  @media(max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  @media(max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 20px;
`;

  // ${props => props.img !== undefined && `
  //   position: absolute;
  //   justify-content: space-between;
  // `}

  //  background-color: rgba(255, 255, 255, 0.8);
  // padding: 5px;
const FoodLabel = styled.div`
  ${props => props.img !== undefined ? 
    `position: absolute;` :
    `justify-content: space-between;   display: flex;`
  }

`;

  
const FoodContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${props => props.oos ? "#c0392b11" : "white" }

    &:hover {
      cursor: pointer;
      background-color: ${props => props.oos ? "#c0392b11" : "#e7e7e7" };
    }
`;

export const Description = styled.div`
  color: gray;
  font-size: 10px;
  margin-top: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

//Collapsible trigger={sectionName}
const BannerMessageDiv=styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  color: #000000;`;

export function Menu2({ setOpenFood, menu,promotionMessages }) {
  return (
    <MenuStyled>
      {(promotionMessages && promotionMessages.banner_message) && <BannerMessageDiv style={{color: 'green'}}>{promotionMessages.banner_message}</BannerMessageDiv>}
      <MenuContent>
      {menu && menu.map((section, sidx) => (
        section ?
        <Collapsible key={section.id} trigger={section.name}>
          <FoodGrid>
            {section.foods && section.foods.map((food, fidx) => (
              food ?
              <FoodContainer
                key={section.id+food.id} oos={food.oos}
                onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                  setOpenFood(food);
                }}
              >
                <FoodLabel >
                  <div style={{marginRight:'20px'}}><span opacity="0.5">{food.name} </span>{food.oos ? <span style={{backgroundColor: "#c0392b", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}</div>
                  <div>{food.pricelabel? food.pricelabel: formatPrice(food.price/100)}</div>
                </FoodLabel>
                <Description>{food.desc}</Description>
              </FoodContainer> : <div key={sidx+ ' ' + fidx}/>
            ))}
          </FoodGrid>
        </Collapsible>: <div key={sidx}/>
      ))}
      </MenuContent>
    </MenuStyled>
  );
}
