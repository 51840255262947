import React from "react";
import styled from "styled-components";
import { formatPrice,isDiscountedPrice } from "../../Data/FoodData";
import Collapsible from "../Collapsible";
//import { foods } from "../Data/FoodData";
import { Food, FoodGrid, FoodLabel } from "../FoodGrid";

const DialogContent = styled.div`
  overflow: auto;
  min-height: 20px;
  // padding: 0px 40px;
  // padding-bottom: 20px;
`;

const StrikePrice =styled.span`
  font-size: .875rem;
  color: red;
  margin-left:5px;
  text-decoration: line-through;
  `;

const MenuContent = styled(DialogContent)`
  //padding: 20px;
  height: 100%;
`;


export default function MenuTemplate({ setOpenFood, menu, showCount }) {
  
  return (
      <MenuContent>
      {menu && Object.entries(menu).map(([sidx, section]) => (
        section ?
        <Collapsible key={section.id} trigger={section.name + (showCount? ' ('+Object.entries(section.foods).length+')' : '')} open={sidx === "0"}>
          <FoodGrid>
            {section.foods && Object.entries(section.foods).map(([fidx, food]) => ( 
              food ?
              <Food key={section.id+food.id} oos={food.oos}
                img={food.img}
                onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                  setOpenFood(food);
                }}
              >
                <FoodLabel img={food.img}>
                  <div style={{marginRight:'20px'}}><span opacity="0.5">{food.name} </span>{food.oos ? <span style={{backgroundColor: "#e74c3c", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}</div>
                  <div>{food.pricelabel? food.pricelabel: formatPrice(food.price/100) } {isDiscountedPrice(food.original_price,food.price) &&<StrikePrice>{formatPrice(food.original_price/100)}</StrikePrice>}</div>
                </FoodLabel>
              </Food> : <div key={sidx+ ' ' + fidx}/>
            ))}
          </FoodGrid>
        </Collapsible> : <div key={sidx}/>
      ))}
      </MenuContent>
  );

}
