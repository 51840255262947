import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import MenuTemplate from "./Templates/MenuTemplate";
import Menu2Template from "./Templates/Menu2Template";
import MenuImageLeftTemplate from "./Templates/MenuImageLeftTemplate";
import MenuImageRightTemplate from "./Templates/MenuImageRightTemplate";
//import { foods } from "../Data/FoodData";
import Search, {createFilter} from "../Search"
import { POPULAR_SECTION_ID } from "../Hooks/useOrders"
import { corianderGreen } from "../Styles/colors";
import useMediaQuery from '@material-ui/core/useMediaQuery';

/* This was causing the search box to not look proper on iOS.
/*     margin-top: 10px; */
const MenuStyled = styled.div`
  position: relative;
  top: 40px;
  margin: 10px 100px 200px 100px;

  @media(max-width: 1000px){ 
    margin: 10px 50px 200px 50px;
  }
  // Add this part
  @media(max-width: 720px){ 
    width: 94%; 
    margin: auto; 
    margin-bottom: 200px;
  }
`;

const BannerMessageDiv=styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;
  padding-top: 10px;
`

const Tagline = styled.div`
      color:#333333 100%;
      opacity: 0.5;
      font-size: 14px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      fontWeight: bold;
`;

export const Notes = styled.div`
      color:#333333 100%;
      opacity: 0.5;
      font-size: 12px;
      word-spacing:0px;
      text-align: center;
      fontWeight: bold;
`;

const Hours = styled.div`
      color: #27ae60;
      font-size: 15px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
      fontWeight: bold;
`;

const daysOfTheWeek = 
                      {0: 'Sunday',
                      1: 'Monday',
                      2: 'Tuesday',
                      3: 'Wednesday',
                      4: 'Thursday',
                      5: 'Friday',
                      6: 'Saturday'};

const PauseMessage = styled.div`
      color: #e74c3c;
      font-size: 15px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      fontWeight: bold;
`;

//Collapsible trigger={sectionName}

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
`

const CurrentlyClosed = styled.div`
    background-color: #e74c3c; 
    color: #FFFFFF;
    border-radius: 5px;
`

const CancelButton = styled.div`
  margin: 0px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
  width: 80px;
  cursor: pointer;
  background-color: ${corianderGreen};
`;


const searchInputStyle = {
  padding: "7px",
  width: "60%",
  maxWidth: "200px",
  marginRight: "5px"
}
const SearchContainerStyle = {
  position: "fixed",
  zIndex: 2,
  backgroundColor: "white",
  width: "100%",
  paddingTop:"20px",
  //paddingLeft: "10px",
  paddingBottom: "10px"
}

const MenuContainerStyle = {
  position: "relative",
  top: "60px"
}

const SearchInputContainer = styled.div`
  display: flex;
  align-content: center;
  margin: 0px 10px 0px 10px;
  padding-top: 20px;

  @media(max-width: 1000px){ 
    margin: 0px 10px 0px 10px;
  }
  @media(max-width: 720px){
    margin: 0px 10px 0px 10px;
  }
`

export default function MenuWrapper({ searchMobileActive, setSearchMobileActive, setOpenFood, menu, template, restauHours, oobNoOrder, promotionMessages }) {
  //TODO: need to get day from Timezone of the restaurant. Currently it is from the client perspective.
  var dateIndex = new Date().getDay()
  var day = daysOfTheWeek[dateIndex];
  var nextDay = daysOfTheWeek[(dateIndex + 1) % 7];
  // Test Cases 
  //day = "Monday"; nextDay= "Tuesday";

  function hoursString(hoursArray) {
    var hours = "";
    hoursArray.forEach(slot => hours += slot.start + " - " + slot.end + "; ");
    return hours.slice(0, -2);
  }

  const matcheMobilesMediaQuery = useMediaQuery('(max-width: 720px)');
  const [searchTerm, setSearchTerm] = useState(""); //to track search in progress and hide banner

  const [filteredMenu, setFilteredMenu] = useState(menu);
  useEffect(() => {
    let tempMenu = menu;
    if(searchTerm && searchTerm.trim().length > 0 && menu) {
      const filtersFoods = menu.filter(sec => sec.id != POPULAR_SECTION_ID).flatMap(sec => sec.foods).filter(createFilter(searchTerm, ["name", "desc"], {fuzzy: false} ));
      tempMenu = [{id: "searchResult"+searchTerm, name: "Results for "+searchTerm, foods: filtersFoods}];
    }
    setFilteredMenu(tempMenu);
  }, [menu, searchTerm])


  const searchRef = useRef(null);
  // if(searchRef && searchTerm && searchTerm.length > 0) {
  //   searchRef.current 
  //     && searchRef.current.inputRef 
  //     && searchRef.current.inputRef.current
  //     && searchRef.current.inputRef.current.focus();
  // }
  const showCountInResults = searchTerm && searchTerm.length > 0 ? { showCount: true }: {};

  const searchInputRef = useRef();
  const containerMenuRef = useRef();
  //const searchMobileActive = (matcheMobilesMediaQuery && document.activeElement === searchInputRef.current);
  return (
    <MenuStyled ref={containerMenuRef}>
      { !searchMobileActive &&
        <>
          {(promotionMessages && promotionMessages.dine_in_banner_message) && <BannerMessageDiv style={{color: 'green'}}>{promotionMessages.dine_in_banner_message}</BannerMessageDiv>}
          {oobNoOrder && 
            <>
              <Hours> <i className="fas fa-clock"></i>
                {day in restauHours && restauHours[day].length > 0 ? 
                " Kitchen timings: " + hoursString(restauHours[day]) :
                " Closed " + day 
                }
              </Hours> 
              {/* <Notes> If outside of above timings, contact the server to place an order.</Notes>  */}
            </>
          }
          {
            promotionMessages && promotionMessages.dine_in_auto_gratuity && 
                <Notes style={{paddingTop: '5px'}}>{promotionMessages.dine_in_auto_gratuity}</Notes>
          }
        </>
      }

        {<div style={searchMobileActive ? SearchContainerStyle : null} 
          ref={searchInputRef}
        ><SearchInputContainer>
          <Search 
            type={"input"}
            onFocus={()=> {
              if(!matcheMobilesMediaQuery) return;
              const { offsetTop } = searchInputRef.current;
              containerMenuRef.current.scrollTop = offsetTop;
              setSearchMobileActive(true);
            }}
            ref={searchRef} style={searchInputStyle} value={searchTerm} onChange={(term) => {
            setSearchTerm(term);
          }}></Search>
          {searchMobileActive && 
            <CancelButton
            onClick={() => {setSearchTerm(""); setSearchMobileActive(false);}}
            >Cancel</CancelButton> }
        </SearchInputContainer></div>}

        <div style={searchMobileActive ? MenuContainerStyle : null} >
          {template === "tuba" && <Menu2Template menu={filteredMenu} setOpenFood={setOpenFood} {...showCountInResults} />}
          {template === "imageleft" && <MenuImageLeftTemplate menu={filteredMenu} setOpenFood={setOpenFood} {...showCountInResults} /> }
          {template === "imageright" && <MenuImageRightTemplate menu={filteredMenu} setOpenFood={setOpenFood} {...showCountInResults} /> }
          {template === "" && <MenuTemplate menu={filteredMenu} setOpenFood={setOpenFood} {...showCountInResults} /> }
        </div>
    </MenuStyled>
  );
}
