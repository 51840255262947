import React from 'react';
import styled from "styled-components";
import { corianderGreen } from "../Styles/colors";
import { Title } from "../Styles/title";

import { useNotes } from '../Hooks/useNotes';
import { getDateTime } from '../Hooks/useOrders';
import { CenterDiv } from './Order';


export const Dialog = styled.div`
  width: 500px;
  background-color: white;
  position: fixed;
  top: 75px;
  z-index: 20;
  max-height: calc(100% - 200px);
  left: calc(50% - 250px);
  display: flex;
  flex-direction: column;
  // Add this part
  @media(max-width: 720px){ 
    width: 100%; 
    left: 0px; 
    z-index: 20; 
  }
`;

export const DialogContent = styled.div`
  overflow: auto;
  min-height: 20px;
  padding: 0px 20px;
  padding-bottom: 20px;
`;

export const DialogFooter = styled.div`
  box-shadow: 0px -2px 0px 0px #e7e7e7;
  min-height: ${props => props.ht ?  props.ht : "60px" };
  display: flex;
  justify-content: center;
`;

export const ConfirmButton = styled(Title)`
  margin: 10px;
  color: white;
  font-weight: bold;
  height: ${props => props.ht ?  props.ht : "20px" };
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  cursor: pointer;
  background-color: ${corianderGreen};
  ${({ disabled }) =>
    disabled &&
    `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 19;
`;


export const OrderDialogBody = styled.div`
  width: 500px;
  background-color: white;
  position: fixed;
  top: 75px;
  z-index: 18;
  max-height: calc(100% - 175px);
  left: calc(50% - 250px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media(max-width: 720px){ 
    left: 0px;
    top: 0px;
    overflow-y: auto important!;
    width: 100%; 
    z-index: 9998!important;
    -webkit-overflow-scrolling: touch !important;
  }
`;

// const OrderDialogBody = styled(Dialog)`
// z-index: 18;
// `;

const OrderDialogShadow = styled(DialogShadow)`
z-index: 17;
`;


export function NameDialog({name,restauId, tableId, checkinId, setName, dispatchCheckin}){
  const nameNote = useNotes('');

  const database = window.firebase.database();

  
  function saveName(){
    const database = window.firebase.database();
    var checkinRef = database.ref("activeusers/" + restauId + "/" + tableId + "/checkins").push();
    var val = {key: checkinRef.key, name: nameNote.value}
    checkinRef.set(val);
    dispatchCheckin({type: "ADD_SELF", payload: checkinRef.key});

    var checkinHistory = database.ref("checkinhistory/" + restauId + "/" + checkinRef.key)
    checkinHistory.set({key: checkinRef.key, name: nameNote.value, tableId: tableId, userAgent: window.navigator.userAgent, time: getDateTime()});

    setName(nameNote.value);

    // var updates = {}
    // updates["activeusers/" + restauId + "/" + tableId + "/checkins/" + checkinId + "/name"]= nameNote.value;
    // database.ref().update(updates);
  }

  return !(name) || name === '' ? <>
    <OrderDialogShadow />
    <OrderDialogBody>
      <DialogContent>
        <h3 style={{textAlign: "center"}}>How would you like to be addressed by the host / other people on the table ?</h3>
        <CenterDiv>
        <input
          type="text"  
          name="user_name" 
          placeholder="Name / Initials / Nickname"
          style={{ width: "200px" }}
          {...nameNote}
        />
        </CenterDiv>
      </DialogContent>
      <DialogFooter>
        <ConfirmButton 
          disabled={nameNote.value.length === 0}
          onClick={() => {
          saveName();
        }}>
          Save
        </ConfirmButton>
      </DialogFooter>
    </OrderDialogBody>
  </> : <div/>
}
