import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  DialogContent,
  ConfirmButton,
  DialogShadow
} from "../FoodDialog/FoodDialog";
import { formatPrice } from "../Data/FoodData";
import { getPrice } from "../FoodDialog/FoodDialog";
import Rating from '@material-ui/lab/Rating';
import { useNotes } from '../Hooks/useNotes';
import { RadioInput } from "../FoodDialog/Choices";
import { useChoice } from "../Hooks/useChoice";
import { getDateTime }  from '../Hooks/useOrders';
import Config from "../config/Config";
import { parseTodayTime } from "../Utils/util";

const { default: axios } = require('axios');

const DialogFooter = styled.div`
  box-shadow: 0px -2px 0px 0px #e7e7e7;
  min-height: ${props => props.ht ?  props.ht : "60px" };
  display: flex;
  justify-content: center;
`;

const PromotionCard = styled.div`
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.2);
  width: 98%;
  margin-right: 30px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #cc4d29;
  font-size: 18px;
  margin-top: 20px;
}`;

const TipOptions = styled.div`
  display: flex;
  justify-content: space-between;  
`;

const TipLabel = styled.div`
    margin-left: 10px;
`;

const TipRadioInput = styled.input`
  cursor: pointer;
  margin-right: 3px;
`;

const OrderStyled = styled.div`
  position: fixed;
  right: 0px;
  top: 42px;
  width: 400px;

  background-color: white;
  height: calc(100% - 48px);
  z-index: 10;
  box-shadow: 4px 0px 5px 4px grey;
  display: flex;
  flex-direction: column;

  @media(max-width: 720px){ 
    position: relative; 
    width: 100%; 
  }
`;

const ButtonContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const OrderContent = styled(DialogContent)`
  padding: 20px;
  height: 100%;
`;

const OrderContainer = styled.div`
  padding: 5px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  ${({ editable }) =>
    editable
      ? `
    &:hover {
      cursor: pointer;
      background-color: #e7e7e7;
    }
  `
      : `
    pointer-events: none; 
  `}
`;

const OrderContainerClickable = styled.div`
  padding: 5px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
`;

const OrderContainerClickableWithoutBorder = styled.div`
  padding: 5px 0px;
`;
//  grid-template-columns: 20px 150px 20px 60px;
const OrderItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 150px 25px 40px 40px ;
  justify-content: space-between;
`;

const BillItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: auto 60px ;
  justify-content: space-between;
`;

const BillOrderItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 150px 70px 60px ;
  justify-content: space-between;
`;

const RatingItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 150px 100px 20px 50px ;
  justify-content: space-between;
`;


const OverallRatingItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 150px 170px ;
  justify-content: space-between;
`;


const CartDialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 9;
`;


const DetailItem = styled.div`
  color: gray;
  font-size: 10px;
`;

const RightAlignPrice = styled.div`
  text-align: right;
`;

const StarRating = styled.div`
  text-align: left;
`;

const StarRatingRightAlign = styled.div`
  text-align: right;
`;

function sendOrder(orders, { email, displayName }) {
  const database = window.firebase.database();

  var newOrderRef = database.ref("orders").push();
  const newOrders = orders.map(order => {
    return Object.keys(order).reduce((acc, orderKey) => {
      if (!order[orderKey]) {
        // undefined value
        return acc;
      }
      if (orderKey === "toppings") {
        return {
          ...acc,
          [orderKey]: order[orderKey]
          .filter(({ checked }) => checked)
          .map(({ name }) => name)
        };
      }
      return {
        ...acc,
        [orderKey]: order[orderKey]
      };
    }, {});
  });
  newOrderRef.set({
    order: newOrders,
    email,
    displayName
  });
}

const orderStatusDict = {
"WAITER" : "🤵",
"COOK" : "👨‍🍳",
"DELIVERED" : "🍽" 
};

const orderStatusLabel = {
  "WAITER" : "Host",
  "COOK" : "Cook",
  "DELIVERED" : "Table" 
  };

export  function getSubTotal(tableOrders){
  const subtotal = Object.keys(tableOrders).reduce((total, key) => {
    if (tableOrders[key].status == "DRAFT") {
    return total;
    } 
    return total + getPrice(tableOrders[key]);
  }, 0);  
  return subtotal;
}  

const RatingsContainer = styled.div`
  display: flex;
  margin: 20px 0px;

`;

export function Order({ orders, setOrders, setOpenFood, login, loggedIn, toggleShowOrders, openOrderDialog, 
  setOpenOrderDialog, tableId, restauId, restauTableKey, disableOrdering, salesTax, serviceCharge, reviews, dispatchReviews, 
  getRatings, reviewComment, appComment, reviewSubmitted, setReviewSubmitted, orderHistoryId, disableHelp, enqueueSnackbar, 
  checkPlease, setOrderHistoryId, tip, setTip, name, promotionMessages, restauHours, oobNoOrder, outsideBizHours, setOutsideBizHours, staleCheckin, forceDetach}) {

    useEffect(() => {
      if (restauId === 'None' || !oobNoOrder || !restauHours){
          return;
      }
      const fetchOutsizeBizHoursFn = async () => {
          const now = new Date();
          const dayStr = now.toLocaleDateString("en-US", {weekday: 'long'});
          if(dayStr in restauHours) {
              const operationHours = Object.values(restauHours[dayStr]);
              let obh = operationHours.findIndex(timings => {
                  return now >= parseTodayTime(timings.start) && now <= parseTodayTime(timings.end)
              }) < 0; //now doesnt fall in any of open time range
              setOutsideBizHours(obh);
          } else {
              setOutsideBizHours(true); //closed for the day.
          }
      }        
      fetchOutsizeBizHoursFn();
      const checkBizHoursTimer = setInterval(() => fetchOutsizeBizHoursFn(), 1000*60*1); //every min
      return () => clearInterval(checkBizHoursTimer);
  }, [restauId, oobNoOrder, restauHours]);
  
  useEffect(() => {
    if(forceDetach || staleCheckin) {
      setOpenOrderDialog(false);
    }
  }, [staleCheckin, forceDetach]);

  // const subtotal = orders.reduce((total, order) => {
  //   return total + getPrice(order);
  // }, 0);
  // const tax = subtotal * 0.07;
  // const total = subtotal + tax;
  const tipRadio = useChoice(-1);
  const tipOther = useNotes();
  const [isDeleteInProgress, setDeleteInProgress] = useState(false);
  const [isPlaceOrderProgress, setPlaceOrderInProgress] = useState(false);

  const database = window.firebase.database();

  async function deleteItem(itemKey) {
    if (isDeleteInProgress){
      return;
    }
    setDeleteInProgress(true);
    var url = Config.delete_dinein_item_url;
    try{
        var resp = await axios.post(url, {restauTableKey, itemKey});
        var status = resp.data.status;
        if (!status){
          enqueueSnackbar("Could not remove item. Call server for help.",  {variant:'error'});
        }
    }catch(error){
        enqueueSnackbar("Could not remove item. Retry.",  {variant:'error'});
    }finally{
      setDeleteInProgress(false);
      return;
    }
  };

  function editOrderItem(food) {
    //toggleShowOrders();
    setOpenFood(food);
  }

  async function placeOrder() {
    if(isPlaceOrderProgress) return;
    setPlaceOrderInProgress(true);
    var url = Config.dinein_place_order_url;
    try{
        var resp = await axios.post(url, {restauTableKey, name, draftOrders});
        var status = resp.data.status;
        if (!status){
          enqueueSnackbar("Could not place order. Call server for help.",  {variant:'error'});
        }
    }catch(error) {
        enqueueSnackbar("Could not place order. Retry.",  {variant:'error'});
    }finally{
      setPlaceOrderInProgress(false);
      return;
    }

    // var updates = {};
    // var dt = getDateTime(); 
    // for (const key in draftOrders){
    //   updates["orders/"+restauTableKey+"/" + key + "/status"] = "WAITER";
    //   updates["orders/"+restauTableKey+"/" + key + "/placedBy"] = name;
    //   updates["orders/"+restauTableKey+"/" + key + "/submitted_timestamp"] = dt;
    // }
    // database.ref().update(updates);
  }


  function close() {
    toggleShowOrders();
  }

  function closeCheckRequest() {
    setOpenOrderDialog(false);
  }

  function setRating(item, value){
    var key;
    if ('id' in item){
      key = item.id;
    }else{
      // firebase keys cannot contain ., $, #, [, ], /, 
      key = item.name.replace(/[.$#[]\//g,' ');
    }

    var val = {"id": key, "rating": value};

    if ('name' in item){
      val['name'] = item.name;
    }else{
      val['name'] = key;
    }

    dispatchReviews({key, value: val});
  }

  function getReviewRatings(item){
    var key;
    if ('id' in item){
      key = item.id;
    }else{
      // firebase keys cannot contain ., $, #, [, ], /, 
      key = item.name.replace(/[.$#[]\/]/g,' ');
    }
    if (getRatings(key)){
      return getRatings(key).rating;
    }

    return 0;
  }

  function saveReview(){
    //debugger;
    var reviewDb = {};
    var ratings = {};
    for (var r in reviews){
      if (reviews[r].rating){
        ratings[r] = reviews[r];
      }
    }


    if (Object.keys(ratings).length > 0 || reviewComment.value.trim() !== ''  || appComment.value.trim() !== '') {
      if (reviewComment.value.trim() !== '') {
        reviewDb['comments'] = reviewComment.value;
      }

      if (appComment.value.trim() !== '') {
        reviewDb['app_feedback'] = appComment.value;
      }

      var dateTime = getDateTime();
      reviewDb['time'] = dateTime;
      reviewDb['orderHistoryId'] = orderHistoryId;
      reviewDb['ratings'] = ratings;
      var reviewRef = database.ref("reviews_dinein/"+restauId).push();
      console.log("review to save ", reviewDb);
      reviewRef.set(reviewDb);
      enqueueSnackbar("Thank You for feedback. Review is saved", {variant:'success'});
      setReviewSubmitted(reviewRef.key);
    }
  }

  function updateReview(){

    var review = {...reviews};
    if (reviewComment.value !== '') {
       review['comments'] = reviewComment.value;
    }

    if (Object.keys(review).length > 0) {
      var dateTime = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
      review['time'] = dateTime;
      review['orderHistoryId'] = orderHistoryId;
      var updates = {};
      updates["reviews_dinein/"+restauId+"/"+reviewSubmitted] = review;
      enqueueSnackbar("Review is updated", {variant:'info'});
      database.ref().update(updates);
    }
  }

  var draftOrders = {};
  var otherOrders = {};
  for (const key in orders) {
    if (orders[key].status === "DRAFT") {
      draftOrders[key] =  orders[key];
    }else {
      otherOrders[key] = orders[key];
    }
  }

  function validateNumber(event) {
    var key = window.event ? event.keyCode : event.which;
    console.log(event.keyCode);
    console.log(event.target.value);
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else if (key === 190 || key === 110) {
      if (event.target.value.includes(".")){
        return false;
      }else{
        return true;
      }
    } else {
    	return true;
    }
};
  function handleChangeTipOther(event) {
    tipOther.onChange(event);
  }

  function setTipOtherRadio(){
    tipRadio.setValue('Other');
    if (!tipOther.value){
      tipOther.setValue('5');
    }
  }

  function getTip(value, tipPercentArg){
    if (tipPercentArg) {
      var ret = value/100.0*parseFloat(tipPercentArg);
      ret = Math.round(ret);
      return ret;
    }
    return 0;
  }

  async function callForCheck(tipTmp) {
    if (checkPlease) {
      // already someone called for check.
      setOpenOrderDialog(false);
      return;
    }
    var url = Config.dinein_call_check_url;
    try{
        var resp = await axios.post(url, {restauId, tableId, name, tip: tipTmp, orderHistoryId});
        var status = resp.data.status;
        if (!status){
          enqueueSnackbar("Could not call check. Call server for help.",  {variant:'error'});
        }
    }catch(error){
        enqueueSnackbar("Could not call check. Retry.",  {variant:'error'});
    }finally{
      setOpenOrderDialog(false);
      return;
    }

    // var updates = {};
    // var ohi = orderHistoryId;
    // if (!ohi || ohi === ''){
    //   var orderHistRef = database.ref("summons/"+restauId).push();
    //   ohi = orderHistRef.key;
    //   setOrderHistoryId(ohi); // Extra not needed as this variable will be updated when DB update reaches.
    // }
    

    // updates["summons/"+restauId+"/" + tableId + "/check"] = true;
    // updates["summons/"+restauId+"/" + tableId + "/check_timestamp"] = getDateTime();

    // updates["summons/"+restauId+"/" + tableId + "/checkActionBy"] = name;

    // updates["summons/"+restauId+"/" + tableId + "/tableId"] = tableId;
    // updates["summons/"+restauId+"/" + tableId + "/tip"] = tipTmp;
    // updates["activeusers/"+restauId+"/" + tableId + "/tip"] = tipTmp; 

    // updates["activeusers/"+restauId+"/" + tableId + "/checkPlease"] = {status: true, actionBy: name};
    // // To communicate orderHistoryId with other users.
    // updates["activeusers/"+restauId+"/" + tableId + "/orderHistoryId"] = ohi; 
    // // To communicate orderHistoryId with the waiter 
    // updates["summons/"+restauId+"/" + tableId + "/orderHistoryId"] = ohi;
    // database.ref().update(updates);
    // setOpenOrderDialog(false);
  }

  var subTotal = getSubTotal(otherOrders);
  var tax = Math.round(subTotal * (salesTax/100.0));
  var serviceFees = Math.round(subTotal * (serviceCharge/100.0));
  var defatultTipPercent = 15;
  var tipRadioValue = tipRadio.value != -1 ? tipRadio.value: defatultTipPercent;
  var tipPercent = tipRadioValue === 'Other'? tipOther.value : tipRadioValue;
  console.log('tipPercent',tipPercent);
  //debugger;
  var tipTmp = getTip(subTotal, tipPercent);

  
  if (openOrderDialog) {

    var orderNames = {};
    function radioOnChange(e){
      if (e.target.value === 'Other'){
        tipRadio.setValue(e.target.value);
        if (!tipOther.value){
          tipOther.setValue('5');
        }
        return;
      }
      tipRadio.setValue(+e.target.value);
    }

    // Confirm billing
    return (
      <>
      <CartDialogShadow onClick={closeCheckRequest} />
      <OrderStyled>
      <OrderContent>
      {(promotionMessages && promotionMessages.checkout_message) &&
          <PromotionCard>
          <div style={{"width":"100%"}}>{promotionMessages.checkout_message}</div>  
          </PromotionCard>

        }
      {(promotionMessages && promotionMessages.dine_in_auto_gratuity) &&
          <PromotionCard>
          <div style={{"width":"100%"}}>{promotionMessages.dine_in_auto_gratuity}</div>  
          </PromotionCard>

        }
        <OrderContainerClickableWithoutBorder>
            <h3>Confirm Tip and Request Check</h3>
          </OrderContainerClickableWithoutBorder>
        {" "}
    <OrderContainer><b>Table-{tableId}: Hey {name}, your billed items:</b></OrderContainer>
        {Object.keys(otherOrders).length > 0 && Object.keys(otherOrders).map((key, index) => (
            <OrderContainer>
              <BillOrderItem>
                <div>{orders[key].name}</div>
                <div>{orders[key].quantity}</div>
                <RightAlignPrice>{formatPrice(getPrice(orders[key])/100)}</RightAlignPrice>
              </BillOrderItem>
              {orders[key].modifiersStr && <DetailItem> (+) {orders[key].modifiersStr}</DetailItem>}
              {orders[key].toppingsStr && <DetailItem> (+) {orders[key].toppingsStr}</DetailItem>}
              {orders[key].choice && <DetailItem> (+) {orders[key].choice}</DetailItem>}
              {orders[key].notes && <DetailItem> <i class="fas fa-info-circle"></i> {orders[key].notes}</DetailItem>}
              {orders[key].addedBy && <DetailItem>Added by - {orders[key].addedBy === name? 'you' : orders[key].addedBy}</DetailItem>}
              {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy != orders[key].updatedBy && <DetailItem>Updated by - {orders[key].updatedBy === name? 'you' : orders[key].updatedBy}</DetailItem>}

            </OrderContainer>
        ))} 
        <OrderContainerClickable>
                <BillItem>
                  <div>Sub-Total</div>
                  <RightAlignPrice>{formatPrice(subTotal/100)}</RightAlignPrice>
                </BillItem>
                <BillItem>
                  <div>Tax</div>
                  <RightAlignPrice>{formatPrice(tax/100)}</RightAlignPrice>
                </BillItem>
                {serviceCharge ? <BillItem>
                  <div>Service Charge {serviceCharge}%</div>
                  <RightAlignPrice>{formatPrice(serviceFees/100)}</RightAlignPrice>
                </BillItem> : null
                }
                <BillItem>
                            <div>Tip</div>
                            <div />
                            <TipOptions>
                              <div>
                              <label>
                                  <TipRadioInput
                                    type="radio"
                                    id="tip"
                                    name="tip"
                                    value={10}
                                    checked={tipRadioValue === 10}
                                    onChange={radioOnChange}
                                  />
                                    10%
                                </label>                    
                              </div>

                              <TipLabel>
                              <label>
                                  <TipRadioInput
                                    type="radio"
                                    id="tip"
                                    name="tip"
                                    value={15}
                                    checked={tipRadioValue === 15}
                                    onChange={radioOnChange}
                                  />
                                    15%
                                </label>   
                              </TipLabel>

                              <TipLabel>
                              <label>
                                  <TipRadioInput
                                    type="radio"
                                    id="tip"
                                    name="tip"
                                    value={20}
                                    checked={tipRadioValue === 20}
                                    onChange={radioOnChange}
                                  />
                                    20%
                                </label> 
                              </TipLabel>
                              <TipLabel>
                              <label>
                                  <TipRadioInput
                                    type="radio"
                                    id="tip"
                                    name="tip"
                                    value="Other"
                                    checked={tipRadioValue === "Other"}
                                    onChange={radioOnChange}
                                  />
                                <input
                                pattern="[0-9]*" name="tip-other" placeholder="Any"
                                value={tipOther.value}
                                style={{ width: "25px" }}
                                min="0" max="999"
                                onKeyDown={validateNumber}
                                onChange={handleChangeTipOther}
                                onFocus={setTipOtherRadio}
                                disabled={tipRadioValue !== 'Other'}

                              />%
                                </label> 

                              </TipLabel>
                            </TipOptions>
                            <RightAlignPrice>
                            {tipRadioValue === "Other" ? 
                              formatPrice(getTip(subTotal,tipOther.value)/100)
                              : formatPrice(getTip(subTotal, tipRadioValue)/100)}
                            </RightAlignPrice>
                          </BillItem>
                </OrderContainerClickable>

                <OrderContainer>
                <BillItem>
                  <div>Total</div>
                  <RightAlignPrice>{formatPrice((subTotal+tax+tipTmp+serviceFees)/100)}</RightAlignPrice>
                  {/* <div>{tipRadio.value === "Other" ? 
                    tipOther.value ? formatPrice(subTotal+tax+ (+tipOther.value)): formatPrice(subTotal+tax) :
                    formatPrice(subTotal+tax+tip)
                    }</div> */}
                </BillItem>
                </OrderContainer>

        <OrderContainer>
        <p>
          Press confirm to notify your host and they will bring the check soon. 
          Once confirmed, you or other guests won't be able to add order items. 
          But your server can still update or add any last minute cravings. 
        </p>

        </OrderContainer>

        {/* {disableHelp == false &&  */}
        <ButtonContainer>
              <ConfirmButton onClick={() => {callForCheck(tipTmp);}}>Confirm</ConfirmButton>
              <ConfirmButton onClick={closeCheckRequest}>Cancel</ConfirmButton>
        </ButtonContainer>
        
        </OrderContent>
{/* 
        <DialogFooter>
          Visit us again👋
        </DialogFooter> */}
      </OrderStyled>
      </>      

    )
  }

  if (disableOrdering) {
    // var subTotal = getSubTotal(otherOrders);
    // var tax = subTotal * (salesTax/100.0);
    // var total = subTotal + tax;
    var orderNames = {};
    for (const key in otherOrders) {
      if (otherOrders[key].name in orderNames) {
        otherOrders[key]['showStarRating'] = false;
      } else {
        otherOrders[key]['showStarRating'] = true;
        orderNames[otherOrders[key].name] = true;
      }
    }

    // Review Page
    return (
      <>
      <CartDialogShadow onClick={close} />
      <OrderStyled>
      <OrderContent>
      {(promotionMessages && promotionMessages.checkout_message) &&
          <PromotionCard>
          <div style={{"width":"100%"}}>{promotionMessages.checkout_message}</div>
          </PromotionCard>

        }
        <OrderContainer>
        {reviewSubmitted == false ? 
              (<h3>Rate and help us improve,&nbsp;{name}.</h3>):
              (<h3>Thanks for the feedback,&nbsp;{name}.</h3>)
        }
          
          </OrderContainer>
        {" "}
        <OverallRatingItem>
              <div>App ordering:</div>
                <StarRatingRightAlign><Rating name="Online_Ordering" defaultValue={getReviewRatings({id: "Online_Ordering"})} precision={0.5} readOnly={reviewSubmitted} onChange={(e,val)=> setRating({id: "Online_Ordering"}, val)}/>
                </StarRatingRightAlign>
                <div></div>
          </OverallRatingItem>
        <OverallRatingItem>
              <div>Food:</div>
                <StarRatingRightAlign><Rating name="Overall_Food" defaultValue={getReviewRatings({id: "Overall_Food"})} precision={0.5} readOnly={reviewSubmitted} onChange={(e,val)=> setRating({id: "Overall_Food"}, val)}/>
                </StarRatingRightAlign>
                <div></div>
          </OverallRatingItem>
          <OverallRatingItem>
              <div>Service:</div>
                <StarRatingRightAlign><Rating name="Overall_Service" defaultValue={getReviewRatings({id: "Overall_Service"})} precision={0.5} readOnly={reviewSubmitted} onChange={(e,val)=> setRating({id: "Overall_Service"}, val)}/>
                </StarRatingRightAlign>
                <div></div>
          </OverallRatingItem>
          <RatingsContainer>App Review:&nbsp;        <textarea
          value={appComment.value}
          onChange={appComment.onChange}
          rows={4}
          cols={100}
          disabled={reviewSubmitted}
        /></RatingsContainer>
              <RatingsContainer>Food Review:&nbsp;        <textarea
          value={reviewComment.value}
          onChange={reviewComment.onChange}
          rows={4}
          cols={100}
          disabled={reviewSubmitted}
        /></RatingsContainer>

        <OrderContainerClickableWithoutBorder><b>Your table-{tableId}'s billed items:</b></OrderContainerClickableWithoutBorder>
        {Object.keys(otherOrders).length > 0 && Object.keys(otherOrders).map((key, index) => (
            <OrderContainer editable={!(reviewSubmitted)}>
              <RatingItem>
                <div>{orders[key].name}</div>
                <StarRating>{orders[key]['showStarRating'] && <Rating name={orders[key].name} defaultValue={getReviewRatings(orders[key])} precision={0.5} readOnly={reviewSubmitted} 
                   size='small' onChange={(e,val)=> setRating(orders[key], val)}/>}
                </StarRating>
                <div>{orders[key].quantity}</div>
                <RightAlignPrice>{formatPrice(getPrice(orders[key])/100)}</RightAlignPrice>
              </RatingItem>
              {orders[key].modifiersStr && <DetailItem> (+) {orders[key].modifiersStr}</DetailItem>}
              {orders[key].toppingsStr && <DetailItem> (+) {orders[key].toppingsStr}</DetailItem>}
              {orders[key].choice && <DetailItem> (+) {orders[key].choice}</DetailItem>}
              {orders[key].notes && <DetailItem> <i class="fas fa-info-circle"></i> {orders[key].notes}</DetailItem>}
              {orders[key].addedBy && <DetailItem>Added by - {orders[key].addedBy === name? 'you' : orders[key].addedBy}</DetailItem>}
              {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy != orders[key].updatedBy && <DetailItem>Updated by - {orders[key].updatedBy === name? 'you' : orders[key].updatedBy}</DetailItem>}

            </OrderContainer>
        ))} 
        <OrderContainer>
                <RatingItem>
                  <div>Sub-Total</div>
                  <div />
                  <div />
                  <RightAlignPrice>{formatPrice(subTotal/100)}</RightAlignPrice>
                </RatingItem>
                <RatingItem>
                  <div>Tax</div>
                  <div />
                  <div />
                  <RightAlignPrice>{formatPrice(tax/100)}</RightAlignPrice>
                </RatingItem>
                {serviceCharge ? <RatingItem>
                  <div>Service Charge {serviceCharge}%</div>
                  <div />
                  <div />
                  <RightAlignPrice>{formatPrice(serviceFees/100)}</RightAlignPrice>
                </RatingItem> : null}
                <RatingItem>
                  <div>Tip</div>
                  <div />
                  <div />
                  <RightAlignPrice>{formatPrice(tip/100)}</RightAlignPrice>
                </RatingItem>
                <RatingItem>
                  <div>Total</div>
                  <div />
                  <div />
                  <RightAlignPrice>{formatPrice((subTotal+tax+tip+serviceFees)/100)}</RightAlignPrice>
                </RatingItem>
        </OrderContainer>
        {/* {disableHelp == false &&  */}
        <ButtonContainer>
              {reviewSubmitted == false ? 
              (<ConfirmButton onClick={() => {saveReview();}}>Submit Review</ConfirmButton>):
              (<div>Thank you for your feedback.</div>)
              // (<ConfirmButton onClick={updateReview}>Update Review</ConfirmButton>)
              }
        </ButtonContainer>

        <CenterDiv>
        Thanks for supporting local businesses!
        </CenterDiv>
        </OrderContent>

        {/* <CenterDiv>
        Thanks for supporting your local business and our staff.
        </CenterDiv>
        <CenterDiv>
        Hope you had a wonderful experience🦄
        </CenterDiv>
        </OrderContent>

        <DialogFooter>
          Visit us again👋
        </DialogFooter> */}
      </OrderStyled>
      </>      

    )
  }


  // CART 
  return (
    <>
    <CartDialogShadow onClick={close} />
    <OrderStyled>
      {Object.keys(orders).length === 0 ? (
        <>
        <OrderContent> <b>Table-{tableId} : Hey {name}, you can keep on adding items to the order until you call for the check.</b>
        </OrderContent>
        <ButtonContainer>
                <ConfirmButton onClick={toggleShowOrders }>Add items to tab</ConfirmButton>
          </ButtonContainer>
        </>
      ) : (
        <OrderContent>
          {(promotionMessages && promotionMessages.checkout_message) &&
          <PromotionCard >
          <div style={{"width":"100%"}}>{promotionMessages.checkout_message}</div>
          </PromotionCard>

        }
          {" "}

        {/* <OrderContainer><b> Your Table-{tableId}'s Order Items so far: </b><br/>
        <i>Keep on adding more items to the order until you call for check.</i>
        </OrderContainer>{" "} */}

        {Object.keys(draftOrders).length > 0 &&
            !(outsideBizHours && oobNoOrder)
            &&  <PromotionCard >
              <div style={{"width":"100%"}}>Place order as soon as you decide. You can add more items any time later.</div>  
          </PromotionCard>
        }

        {(outsideBizHours && oobNoOrder) &&
            <PromotionCard>
              <div style={{"width":"100%"}}>Kitchen is closed, please place order by calling Server. </div>  
            </PromotionCard>

        }

        {Object.keys(draftOrders).length > 0 && <OrderContainerClickableWithoutBorder>
          <b>Table-{tableId} : Hey {name}, place order for : </b>
        </OrderContainerClickableWithoutBorder>}
        
        {" "}


          {Object.keys(draftOrders).length > 0 && Object.keys(draftOrders).map((key, index) => (
            <OrderContainer editable>
              <OrderItem>
                <div>{orders[key].name}</div>
                <div>{orders[key].quantity}</div>
                {/* <div><b>✏️</b></div> */}
                <div
                style={{ cursor: "pointer" }}
                onClick={e => {
                  e.stopPropagation();
                  editOrderItem({ ...orders[key], index, key });
                }}
                >
                  <span role="img" aria-label="edit" style={{color: "#27ae60"}}><i className="fas fa-edit"></i></span>
                </div>
                
                <div
                style={{ cursor: "pointer" }}
                onClick={async (e) => {
                  e.stopPropagation();
                  await deleteItem(key);
                }}
                >
                <span role="img" aria-label="delete" style={{color: "#27ae60"}}><i className="fas fa-trash-alt"></i></span>
                {/* 🗑 */}
                </div>
                {/* <div>{formatPrice(getPrice(order))}</div> */}
              </OrderItem>
              {orders[key].modifiersStr && <DetailItem> (+) {orders[key].modifiersStr}</DetailItem>}
              {orders[key].toppingsStr && <DetailItem> (+) {orders[key].toppingsStr}</DetailItem>}
              {orders[key].choice && <DetailItem> (+) {orders[key].choice}</DetailItem>}
              {orders[key].notes && <DetailItem> <i class="fas fa-info-circle"></i> {orders[key].notes}</DetailItem>}
              {orders[key].addedBy && <DetailItem>Added by - {orders[key].addedBy === name? 'you' : orders[key].addedBy}</DetailItem>}
              {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy != orders[key].updatedBy && <DetailItem>Updated by - {orders[key].updatedBy === name? 'you' : orders[key].updatedBy}</DetailItem>}

            </OrderContainer>
          ))}
          
         
          <OrderContainerClickableWithoutBorder>
            
              {/* {Object.keys(draftOrders).length > 0 && <CenterDiv><i>After submitting to tab, you can still add more items later.</i></CenterDiv>} */}

              <ButtonContainer>
                <ConfirmButton onClick={toggleShowOrders }>Add more items</ConfirmButton>
                {Object.keys(draftOrders).length > 0 &&
                   <ConfirmButton disabled={(outsideBizHours && oobNoOrder) || isPlaceOrderProgress}
                            onClick={() => {placeOrder();}}>Place order</ConfirmButton>}
              </ButtonContainer>
          </OrderContainerClickableWithoutBorder>


          {(Object.keys(otherOrders).length > 0 && promotionMessages && promotionMessages.dine_in_submit_message) &&
            <PromotionCard>
              <div style={{"width":"100%"}}>{promotionMessages.dine_in_submit_message}</div>  
            </PromotionCard>

          }
          
          {Object.keys(draftOrders).length === 0 && Object.keys(otherOrders).length > 0 &&
              <OrderContainer><b>Table-{tableId} : Hey {name}, ordered items:</b></OrderContainer>
          }
          {Object.keys(draftOrders).length > 0 && Object.keys(otherOrders).length > 0 && 
                    <OrderContainer><b>Ordered Items:</b></OrderContainer>
          }
          {Object.keys(otherOrders).length > 0 && Object.keys(otherOrders).reverse().map((key, index) => (
            <OrderContainer>
              <OrderItem>
                <div>{orders[key].name}</div>
                <div>{orders[key].quantity}</div>
                <div><b>{orderStatusDict[orders[key].status]}</b></div>
                <div>{orderStatusLabel[orders[key].status]}</div>
                {/* <div>{formatPrice(getPrice(order))}</div> */}
              </OrderItem>
              {orders[key].modifiersStr && <DetailItem> (+) {orders[key].modifiersStr}</DetailItem>}
              {orders[key].toppingsStr && <DetailItem> (+) {orders[key].toppingsStr}</DetailItem>}
              {orders[key].choice && <DetailItem> (+) {orders[key].choice}</DetailItem>}
              {orders[key].notes && <DetailItem> <i class="fas fa-info-circle"></i> {orders[key].notes}</DetailItem>}
              {orders[key].addedBy && <DetailItem>Added by - {orders[key].addedBy === name? 'you' : orders[key].addedBy}</DetailItem>}
              {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy != orders[key].updatedBy && <DetailItem>Updated by - {orders[key].updatedBy === name? 'you' : orders[key].updatedBy}</DetailItem>}

            </OrderContainer>
          ))}

          {Object.keys(otherOrders).length > 0 &&           
          <OrderContainer>
            <OrderContent>
              Order Item Status Symbols:<br/>
                   Host / Server - 🤵<br/>
                   Cook / Kitchen - 👨‍🍳<br/>
                   On Table - 🍽
            </OrderContent>
            {/* <OrderItem>
              <div />
              <div>Received by Host - </div>
              <div>🤵</div>
            </OrderItem>
            <OrderItem>
              <div />
              <div>Cook working on it - </div>
              <div>👨‍🍳</div>
            </OrderItem>
            <OrderItem>
              <div />
              <div>On table -</div>
              <div>🍽</div>
            </OrderItem> */}
          </OrderContainer>
          }

          {/* <OrderContainer>
            <OrderItem>
              <div />
              <div>Sub-Total</div>
              <div>{formatPrice(subtotal)}</div>
            </OrderItem>
            <OrderItem>
              <div />
              <div>Tax</div>
              <div>{formatPrice(tax)}</div>
            </OrderItem>
            <OrderItem>
              <div />
              <div>Total</div>
              <div>{formatPrice(total)}</div>
            </OrderItem>
          </OrderContainer> */}
        </OrderContent>
      )}
      {Object.keys(orders).length > 0 && <DialogFooter>
         <ConfirmButton 
           disabled={Object.keys(otherOrders).length == 0 }
           onClick={() => {
           //toggleShowOrders();
           if (Object.keys(draftOrders).length > 0) {
              let bill = window.confirm("There are some items still not submitted to the tab. Are you sure you don't want to order them! Press Ok if you want to go ahead and call for check. Press Cancel to continue ordering.");
              if (!bill){
                return;
              }
           }
           let confirmation = window.confirm("Are you sure you don't want to order anything else later? Press Ok if you are done and want the check.");
           if (!confirmation){
             return;
           }
           setOpenOrderDialog(true);
           window.scrollTo(0, 0);
          //sendOrder(orders, loggedIn);
          // if (loggedIn) {
          //   setOpenOrderDialog(true);
          //   sendOrder(orders, loggedIn);
          // } else {
          //   login();
          // }
        }}>Request check</ConfirmButton>
      </DialogFooter> }
    </OrderStyled>
    </>
  );
}
