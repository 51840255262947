import React, { useState } from "react";
import { Navbar } from "./Navbar/Navbar";
import { Banner } from "./Banner/Banner";
import { Menu } from "./Menu/Menu";
import { Menu2 } from "./Menu/Menu2";
import { MenuImageRight } from "./Menu/MenuImageRight";
import { MenuImageLeft } from "./Menu/MenuImageLeft";
import { FoodDialog } from "./FoodDialog/FoodDialog";
import { GlobalStyle } from "./Styles/GlobalStyle";
import { Order } from "./Order/Order";
import { useOpenFood } from "./Hooks/useOpenFood";
import { useOrders } from "./Hooks/useOrders";
import { useTitle } from "./Hooks/useTitle";
import {OrderDialog} from "./Order/OrderDialog";
import {useOrderDialog} from "./Hooks/useOrderDialog";
import {useHelpDialog} from "./Hooks/useHelpDialog";
import {useShowOrders} from "./Hooks/useShowOrders";
import { HelpDialog } from "./Order/HelpDialog";
import { ViewCart } from "./Order/ViewCart";
import { useNotes } from "./Hooks/useNotes";
import { useReviewSubmitted } from "./Hooks/useReviewSubmitted";
// import {useRestauAndTable} from "./Hooks/useRestauAndTable";
//import {GlobalLoad} from "./Navbar/GlobalLoad"
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { NameDialog } from "./Order/NameDialog";
import MenuWrapper from "./Menu/MenuWrapper";



function MyApp() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const openFood = useOpenFood();
  const orders = useOrders(enqueueSnackbar, closeSnackbar);
  //const auth = useAuthentication();
  const orderDialog = useOrderDialog();
  const helpDialog = useHelpDialog();
  const showOrders = useShowOrders();
  const reviewComment = useNotes("");
  const appComment = useNotes("");
  const reviewSubmitted = useReviewSubmitted(false);
  const [searchMobileActive, setSearchMobileActive] = useState(false);


  //const restauAndTable = useRestauAndTable();

  useTitle({ ...openFood, ...orders });

  // <Menu {...openFood} {...orders} />  confirm how to pass only part of properties
  return (
    orders.restauId === 'None' || orders.tableId === 'None'? 
      (<div>Please rescan the barcode. Do not Refresh the page after that, we automatically sync across all devices. If you refresh, you will see this message.</div>) :
    (<>
      <GlobalStyle />
      {/* <OrderDialog {...orderDialog} {...orders} {...showOrders} /> */}
      <NameDialog {...orders}/>
      <HelpDialog {...helpDialog} {...orders} />
      <FoodDialog {...openFood} {...orders} />
      <Navbar {...orders} {...showOrders} {...helpDialog} {...openFood} />
      {showOrders.showOrders && <Order {...orders} {...openFood} {...orderDialog}  {...showOrders} reviewComment={reviewComment} appComment={appComment} {...reviewSubmitted}/>}
      {!(searchMobileActive) && <Banner img={orders.bannerUrl} imgDesktop={orders.bannerUrlDesktop}/>}
    
     
     {/* {orders.template === "tuba" && <Menu2 {...openFood} {...orders} />}
      {orders.template === "imageleft" && <MenuImageLeft {...openFood} {...orders} />}
      {orders.template === "imageright" && <MenuImageRight {...openFood} {...orders} />}
      {orders.template === "" && <Menu {...openFood} {...orders} />}

      */}
      <MenuWrapper {...openFood} {...orders} {...{searchMobileActive, setSearchMobileActive}} />

      <ViewCart {...orders} {...showOrders}/>
    </>)
  
  );
}

function SnackbarCloseButton({ id }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <IconClose />
    </IconButton>
  );
}



function App() {

  return (<SnackbarProvider dense preventDuplicate maxSnack={3} 
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  action={key => <SnackbarCloseButton key={key}  id={key} />}
  disableWindowBlurListener={true}
  hideIconVariant={true}
  // iconVariant={{
  //   success: '✅',
  //   error: '✖️',
  //   warning: '⚠️',
  //   info: 'ℹ️',
  // }}
  >
      <MyApp/>
    </SnackbarProvider>);
}
export default App;

// © made by react.school
