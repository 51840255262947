import firebase from 'firebase/app';
import 'firebase/database';
// import * as firebase from 'firebase';
// import '@firebase/auth';
// import '@firebase/firestore'; 
import Config from './config/Config'

var mainConfig = firebase.initializeApp(Config.firebaseConfig);

  export function initApp(){
  window.firebase = mainConfig;
  return  mainConfig;
}
  