import React from 'react';

function FoodSoldOut() {
    return (
        <span
            style={{
                backgroundColor: '#e74c3c',
                color: '#FFFFFF',
                borderRadius: '5px',
            }}
        >
            <b>Sold Out</b>
        </span>
    );
}

FoodSoldOut.propTypes = {};

export default FoodSoldOut;
