import { useState } from "react";

export function useNotes(defaultNote) {
  const [value, setValue] = useState(defaultNote);

  function onChange(e) {
    setValue(e.target.value);
  }

  return {
    value,
    onChange,
    setValue

  };
}
