import React from 'react';
import styled from "styled-components";

import {Dialog, DialogContent, DialogShadow, DialogFooter, ConfirmButton} from "../FoodDialog/FoodDialog";
import { getDateTime } from '../Hooks/useOrders';



export const OrderDialogBody = styled.div`
  width: 500px;
  background-color: white;
  position: fixed;
  top: 75px;
  z-index: 18;
  max-height: calc(100% - 200px);
  left: calc(50% - 250px);
  display: flex;
  flex-direction: column;

  // Add this part
  @media(max-width: 720px){ 
    width: 100%; 
    left: 0px; 
    z-index: 18; 
  }
`;

// const OrderDialogBody = styled(Dialog)`
// z-index: 18;
// `;

const OrderDialogShadow = styled(DialogShadow)`
z-index: 17;
`;


export function OrderDialog({openOrderDialog, setOpenOrderDialog, setOrders, detachListener,restauId, tableId, toggleShowOrders, checkPlease, setOrderHistoryId, orderHistoryId }){
  
  const database = window.firebase.database();

  function close() {
    setOpenOrderDialog(false);
  }



  function callForCheck() {
    if (checkPlease) {
      // already someone called for check.
      return;
    }
    var updates = {};
    var ohi = orderHistoryId;
    if (!ohi || ohi === ''){
      var orderHistRef = database.ref("summons/"+restauId).push();
      ohi = orderHistRef.key;
      setOrderHistoryId(ohi); // Extra not needed as this variable will be updated when DB update reaches.
    }
    

    updates["summons/"+restauId+"/" + tableId + "/check"] = true;
    updates["summons/"+restauId+"/" + tableId + "/check_timestamp"] = getDateTime();
    updates["summons/"+restauId+"/" + tableId + "/tableId"] = tableId;
    updates["activeusers/"+restauId+"/" + tableId + "/checkPlease"] = {status: true};
    // To communicate orderHistoryId with other users.
    updates["activeusers/"+restauId+"/" + tableId + "/orderHistoryId"] = ohi; 
    // To communicate orderHistoryId with the waiter 
    updates["summons/"+restauId+"/" + tableId + "/orderHistoryId"] = ohi;
    database.ref().update(updates);
  }




  return openOrderDialog ? <>
    <OrderDialogShadow onClick={close}/>
    <OrderDialogBody>
      <DialogContent>
        <h3 style={{textAlign: "center"}}>Hope you had a wonderful experience🦄 </h3>
        <p>
          Thanks for supporting your local business and our staff.
        </p>
        <p>
          Are you sure you don't want to try our desserts or drinks ?
        </p><p>
          Press confirm to notify your host and he/she will bring the check soon. 
          Once confirmed, you or other guests won't be able to add order items. 
          But your server can still update or add any last minute wishes. 
        </p>

        {/* You can view your final check in the cart anytime.<br/>
          Note:<p>
          <small>If you still have some cravings for desserts or called for check by mistake, rescan the QR code to add items to existing order.</small>
        </p> */}
      </DialogContent>
      <DialogFooter>
        <ConfirmButton onClick={() => {
          callForCheck();
          
          //detachListener();
          setOpenOrderDialog(); 
          toggleShowOrders();

        }}>
          Confirm
        </ConfirmButton>
      </DialogFooter>
    </OrderDialogBody>
  </> : <div/>
}
