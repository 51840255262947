import { useState, useEffect, useReducer } from "react";



export const daysOfTheWeek = 
                      {0: 'Sunday',
                      1: 'Monday',
                      2: 'Tuesday',
                      3: 'Wednesday',
                      4: 'Thursday',
                      5: 'Friday',
                      6: 'Saturday'};
                      

export function getDateTime(){
  var dateTime = new Date().toISOString();
  return dateTime;
}
export const POPULAR_SECTION_ID = 'popular';



const reviewReducer = (state, action) => {
  switch (action.type) {
    default:
      var newstate = {...state};
      newstate[action.key] = action.value;
      return newstate;
  }
}

// TODO : forcedetach for single user feature.: Had following code before in the firebase listener hook.
// if (("forceDetach" in result && result.forceDetach) || 
// ("forceDetach" in result['checkins'][checkinId] && result['checkins'][checkinId].forceDetach))

function getDayOfWeek(currDate) {
  let dayOfWeekIndex = currDate.getDay();
  switch (dayOfWeekIndex) {
      case 0:
          return 'Sun';
      case 1:
          return 'Mon';
      case 2:
          return 'Tue';
      case 3:
          return 'Wed';
      case 4:
          return 'Thu';
      case 5:
          return 'Fri';
      case 6:
          return 'Sat';
      default:
          return null;
  }
}

function isTimeMatch(timeObjToCheck, currHour, currMin) {
  /* timeObjToCheck : {
      startHour:'14',
      startMin:'30',
      endHour:'18',
      endMin:'45'
  } */
  if (
      currHour >= timeObjToCheck['startHour'] &&
      currHour <= timeObjToCheck['endHour']
  ) {
      if (
          (currHour === timeObjToCheck['startHour'] &&
              currMin < timeObjToCheck['startMin']) ||
          (currHour === timeObjToCheck['endHour'] &&
              currMin > timeObjToCheck['endMin'])
      ) {
          return false;
      }
      return true;
  } else {
      return false;
  }
}



export function useOrders(enqueueSnackbar, closeSnackbar) {


  const database = window.firebase.database();

  function itemReadyInKitchen(order){
    if (order.status != 'COOK') {
      return false;
    }
    if (!('station' in order)){
      return false;
    }
    for (var s of order.station) {
      if (order[s+'status'] != 'COMPLETED'){
        return false;
      }
    }
    return true;
  }

  const orderReducer = (state, action) => {
    switch (action.type) {
      case 'DELETE':
        var newOrders = {...state};
        delete newOrders[action.key];
        if (action.payload.notify){       
          enqueueSnackbar(action.payload.name + ' deleted.'); 
        }
        return newOrders;
      case 'UPDATE':
        var newOrders = {...state};
        newOrders[action.key] = action.payload;
        if (action.payload.notify){   
          if (action.payload.status === 'COOK'){
            if (itemReadyInKitchen(action.payload)){
              enqueueSnackbar('Items ready in  kitchen/bar.');  
            }else {
              enqueueSnackbar('Items sent to the kitchen/bar.');  
            }
          }else {
            if (action.payload.status === 'WAITER'){
              if ('updatedBy' in action.payload && action.payload.updatedBy === 'Host') {
                // scenario where waiter updates the item on user's behavior.
                enqueueSnackbar(action.payload.name + ' updated by Host.');
              }else if (action.payload.placedBy === name){
                enqueueSnackbar('Order is placed. Host is notified.');
              }else{
                enqueueSnackbar('Order is placed by ' + action.payload.placedBy + ". Host is notified.");
              }
            } else {
              if (action.payload.updatedBy === name){
                enqueueSnackbar(action.payload.name + ' updated.');
              }else{
                enqueueSnackbar(action.payload.name + ' updated by ' + action.payload.updatedBy + ".");
              }
            }
            
          }    
        }
        return newOrders;
      case 'APPEND':
        var newOrders = {...state};  
        newOrders[action.key] = action.payload;
        if (action.payload.notify){ 
          if (action.payload.addedBy === name){
            enqueueSnackbar(action.payload.name + ' added.');
          }else{
            enqueueSnackbar(action.payload.name + ' added by ' + action.payload.addedBy + ".");
          }
          
        }
        return newOrders;
      case 'RESET':
          return {};
      //case 'SOUND_SETTING'
      default:
        throw new Error();
    }
  };
  
  const [name, setName] = useState(localStorage.getItem('nameInLocalStorage') || '');
  const [reviews, dispatchReviews] = useReducer(reviewReducer, {});
  const [checkinId, setCheckinId] = useState("");
  const [orders, dispatchOrder] = useReducer(orderReducer, {});
  //const [orders, setOrders] = useState({});
  const [restauId, setRestauId] = useState("None");
  const [tableId, setTableId] = useState("None");
  const [restauTableKey, setRestauTableKey] = useState("None");
  const [listener, setListener] = useState("None");
  const [orderRef, setOrderRef] = useState("None");
  const [activeUserRef, setActiveUserRef] = useState("None");
  const [restauName, setRestauName] = useState("");
  const [menu, setMenu] = useState();
  const [checkPlease, setCheckPlease] = useState(false);
  const [helpPlease, setHelpPlease] = useState(false);
  const [forceDetach, setForceDetach] = useState(false);
  const [staleCheckin, setStaleCheckin] = useState(false);
  const [disableOrdering, setDisableOrdering] = useState(false);
  const [disableHelp, setDisableHelp] = useState(false);
  const [numberOfCheckins, setNumberOfCheckins] = useState(0);
  const [template, setTemplate] = useState("");
  const [salesTax, setSalesTax] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [orderHistoryId, setOrderHistoryId] = useState("");
  const [tip, setTip] = useState(0);
  const [bannerUrl, setBannerUrl] = useState();
  const [bannerUrlDesktop, setBannerUrlDesktop] = useState();
  const [promotionMessages,setPromotionMessages] = useState({});
  const [restauHours, setRestauHours] = useState({});
  const [oobNoOrder, setOobNoOrder] = useState(false);
  const [outsideBizHours, setOutsideBizHours] = useState(false);


  const checkinReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_SELF':
        var newstate = {...state};
        newstate["self"] = action.payload;
        setCheckinId(action.payload);
        if ("all" in newstate){
          if (!(newstate["self"] in newstate["all"])) {
            newstate["staleCheckin"] = true;
          }
        }
        return newstate;
      case 'ALL':
        var newstate = {...state};
        newstate["all"] = action.payload;
        if ("self" in newstate) {
          if (!(newstate["self"] in newstate["all"])) {
            newstate["staleCheckin"] = true;
            return newstate;
          }
          if ('name' in newstate["all"][newstate["self"]]){
            setName(newstate["all"][newstate["self"]]['name']);
          }
          // for (var c in newstate["all"]){
          //   if (c === newstate["self"] && 'name' in newstate["all"][c]){
          //     setName(newstate["all"][c]['name']);
          //   }else {

          //   }
          // }
        }
        return newstate;
      default:
        throw new Error();
    }
  };
  const [checkin, dispatchCheckin] = useReducer(checkinReducer, {staleCheckin: false});


  function getRatings(key){
    if (!(key in reviews)){
      return 0;
    }
    return reviews[key];
  }

  function getRestauAndTableId(){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const restauId = params.get('restauId')? params.get('restauId'): 'None'; // None
    const tableId = params.get('tableId')? params.get('tableId') : 'None'; // None

    /**
     * TODO : Validate restauId and TableId valid. 
     */
    setRestauId(restauId);
    setTableId(tableId);
    const restauTableKey = restauId+':'+tableId
    setRestauTableKey(restauTableKey);
    console.log(restauId);
    console.log(tableId);
    console.log(window.location.href)
    window.history.replaceState({}, document.title, window.location.href.split("?")[0])
    //window.location.href =  window.location.href.split("?")[0];
    console.log(window.location.href)
    //window.history.replaceState()
    return {restauId, tableId, restauTableKey };
  }



  function setOrderDBListener(restauTableKey){
    if (restauTableKey === "None"){
      return;
    }
    const database = window.firebase.database();
    
    dispatchOrder({type: "RESET"});
    var orderRef = database.ref("orders/"+restauTableKey);
    orderRef.on('child_added', function(data) {
      console.log('child_added', data.val());
      dispatchOrder({type: "APPEND", key: data.key, payload: data.val()});
    });
    
    orderRef.on('child_changed', function(data) {
      console.log('child_changed', data.val());
      dispatchOrder({type: "UPDATE", key: data.key, payload: data.val()});
    });
    
    orderRef.on('child_removed', function(data) {
      console.log('child_removed', data.val());
      dispatchOrder({type: "DELETE", key: data.key, payload: data.val()});
    });

    //let listener = 

    // orderRef.on('value', function(snapshot) {
    //   if (snapshot.val() === null){
    //     //setOrders({}); when we start we are already in this state. 
    //     // shouldnt disable ordering/help else customer wont be able to start ordering. 
    //     // As first time when this value is read from DB, it is null
    //   }else {
    //    setOrders(snapshot.val());
    //    const key = enqueueSnackbar('Order is updated');
    //   }
    // });

    //setListener(listener);
    setOrderRef(orderRef)
  }



  function setActiveUsersDBListener(rid, tid){
    if (rid === "None" || tid === "None") {
      return;
    }
    // const database = window.firebase.database();
    // var checkinRef = database.ref("activeusers/" + rid + "/" + tid + "/checkins").push();
    // var val = {key: checkinRef.key}
    // checkinRef.set(val);
    // dispatchCheckin({type: "ADD_SELF", payload: checkinRef.key});

    // var checkinHistory = database.ref("checkinhistory/" + rid + "/" + checkinRef.key)
    // checkinHistory.set({key: checkinRef.key, tableId: tid, userAgent: window.navigator.userAgent, time: getDateTime()});

    var activeUserRef = database.ref("activeusers/" + rid + "/" + tid );
    setActiveUserRef(activeUserRef);
    activeUserRef.on('value', function(snapshot) {

      if (snapshot.val() != null){
        var result = snapshot.val();
        dispatchCheckin({type: "ALL", payload: result['checkins']});

        if ("tip" in result && result.tip) {
          setTip(result.tip);
        }
        
        if ("forceDetach" in result && result.forceDetach) {
          setForceDetach(true);
          setDisableOrdering(true);
        }
        // if ("checkPlease" in result && result.checkPlease) {
        //   setCheckPlease(true);
        //   setDisableOrdering(true);
        //   enqueueSnackbar('Check called for the table.',{variant:'error'});
        // }
        // if ("helpPlease" in result && result.helpPlease) {
        //   setHelpPlease(true);
        //   enqueueSnackbar('Help called for the table.',{variant:'error'});
        // }else {
        //   setHelpPlease(false);
        //   enqueueSnackbar('Help Request is Cleared.',{variant:'error'});
        // }
        
        if ("orderHistoryId" in result){
          setOrderHistoryId(result.orderHistoryId);
        }
        setNumberOfCheckins(Object.keys(result['checkins']).length);
      }else {
        setCheckPlease(true);
        setDisableHelp(true);
        setDisableOrdering(true);
        detachListener();
      }
    });

    var checkRef = database.ref("activeusers/" + rid + "/" + tid + "/checkPlease");
    checkRef.on('value', function(snapshot) {
      var result = snapshot.val();
      if (snapshot.val() != null){
        if ("status" in result) {
          setCheckPlease(result.status);
          if (result.status) {
            setDisableOrdering(true);
            if (result.actionBy === name){
              enqueueSnackbar('Check called for the table.');
            }else{
              enqueueSnackbar('Check called for the table by ' + result.actionBy + '.');
            }
          }     
        }
      }
    });

    var helpRef = database.ref("activeusers/" + rid + "/" + tid + "/helpPlease");
    helpRef.on('value', function(snapshot) {
      var result = snapshot.val();
      if (snapshot.val() != null){  
        if ("status" in result) {
          setHelpPlease(result.status);
          if (result.status) {
            if (result.actionBy === name){
              enqueueSnackbar('Help called for the table.');
            }else{
              enqueueSnackbar('Help called for the table by ' + result.actionBy + '.');
            }
          } else {
            if (result.actionBy === "Host"){
              enqueueSnackbar('Help request is cleared by host.');
            }else if (result.actionBy === name){
              enqueueSnackbar('Help request is cancelled.');
            }else{
              enqueueSnackbar('Help request is cancelled by ' + result.actionBy + '.');

            }
          }         
        }
      }
    });

  }

  function getVisibilityOverrideResultNew(
    currDate,
    source,
    visibilityOverrideId,
    visibilityOverrides,
    isCategory,
    categoryVisOverrideId
) {
    //debugger;
    //Assumption: Default visibility of items is True
    let defaultVisibility = true;

    if (isCategory) {
        return true;
    }

    //if the visibilityOverride is not specified at foodItem Level, use the one from category level.
    let curVisibilityOverrideId = visibilityOverrideId
        ? visibilityOverrideId
        : categoryVisOverrideId;

    if (!curVisibilityOverrideId || !currDate || !visibilityOverrides) {
        return defaultVisibility;
    }
    if (!visibilityOverrides[curVisibilityOverrideId]) {
        return defaultVisibility;
    }
    // TODO: Dharma
    let visibilityOverride = visibilityOverrides[curVisibilityOverrideId];
    let currHour = currDate.getHours();
    let currMin = currDate.getMinutes();
    let currDay = getDayOfWeek(currDate);
    //  For each Rule in the override
    let matchedHideRule = false;
    let visibilityResult = false;
    visibilityOverride.rules.forEach((rule) => {
        let ruleMatched = false;
        let ruleTime = null;
        // check if the rule's source is matching with the current source
        let tempSource = source;
        if (source === 'Online') {
            tempSource = 'ToGo';
        }
        if (rule.type.includes(tempSource)) {
            // check if the curTime is witin the time defined in the Rule
            if (rule.dateTimeType === '24/7') {
                ruleMatched = true;
            } else {
                if (rule.dateTimeType === 'time') {
                    ruleTime = rule['days']['All Days']['time'];
                } else if (rule.dateTimeType === 'dayTime') {
                    ruleTime =
                        rule['days'][currDay] &&
                        rule['days'][currDay]['time'];
                }
                ruleMatched =
                    ruleTime && isTimeMatch(ruleTime, currHour, currMin);
            }

            if (ruleMatched) {
                //if there is a matched show rule, then return true
                if (rule.status === 'show') {
                    visibilityResult = true;
                    return true;
                } else {
                    matchedHideRule = true;
                }
            }
        }
    });

    //if there is a matched show rule, then already returned true earlier
    // if (visibilityResult) {
    //     return true;
    // }
    //if there is atleast one matched hide rule, then return false
    if (matchedHideRule) {
        return false;
    }
    //if none of the rules match in the override, then return default value as true
    else {
        return defaultVisibility;
    }
}

function getVisibilityOverrideResult(
    visibilityOverride,
    currDate,
    source,
    visibilityOverrideId,
    visibilityOverrides,
    isCategory,
    categoryVisOverrideId
) {
    if (
        (visibilityOverrideId || categoryVisOverrideId) &&
        visibilityOverrides
    ) {
        return getVisibilityOverrideResultNew(
            currDate,
            source,
            visibilityOverrideId,
            visibilityOverrides,
            isCategory,
            categoryVisOverrideId
        );
    }  
    if (!visibilityOverride || !currDate){
      return true;
    }
    var sourcePolicy = visibilityOverride[source];
    if (!sourcePolicy){
      sourcePolicy = visibilityOverride['defaultSource'];
    }
    if (!sourcePolicy){
      return true;
    }
    var defaultVisibility = true;
    if ('defaultVisibility' in sourcePolicy){
      defaultVisibility = sourcePolicy['defaultVisibility'];
    }
    var policy =  sourcePolicy['visibilityPolicy'];
    if (!policy){
      return defaultVisibility;
    }
    var time;
    var display;
    if (policy['policyName'] === 'Timebased'){
      time = policy['time'];
      display = policy['display'];
    } else if (policy['policyName'] === 'Daybased'){
      var daypolicy = policy['days'][daysOfTheWeek[currDate.getDay()]];
      if (!daypolicy){
        return defaultVisibility;
      }
      time = daypolicy['time'];
      display = daypolicy['display'];      
    }
    if (!time){
      return defaultVisibility;
    }

    let currHour = currDate.getHours();
    let currMin = currDate.getMinutes();  
    if((currHour >= time['startHour'] && currHour<= time['endHour']) ){
      if((currHour == time['startHour'] && currMin < time['startMin'])||
      (currHour ==  time['endHour']  && currMin > time['endMin'])){
        return defaultVisibility;
      }
     return display;
   }
   return defaultVisibility;
  }

  function enhanceMenuWithStationAndModifiersAndOverrides(menu, popularItems, storeTimezone, source, defaultStations, modifiers, visibilityOverrides){
    if (!popularItems){ // undefined or null
      popularItems={};
    }
    var popularSection = {id: 'popular', name: 'Popular Items', foods: []};
    console.log('popularItems', popularItems);

    var currDateStoreTZ;
    if (storeTimezone){
      var currDate = new Date();
      //currDate.setHours(currDate.getHours() + 28);
      currDateStoreTZ = new Date(currDate.toLocaleString("en-US", {timeZone: storeTimezone}));
    }

    for (const [section_idx, section] of Object.entries(menu)){
      if (!getVisibilityOverrideResult(
            section.visibilityOverride, 
            currDateStoreTZ,
            source,
            section.visiblityOverrideId,
            visibilityOverrides,
            true,
            null)){
        delete menu[section_idx];
        continue;
      }
      if(!section.foods){
        continue;
      }
      for (const [food_idx, food] of Object.entries(section.foods)){
        
        if (food.hide || 
                !getVisibilityOverrideResult(
                  food.visibilityOverride,
                  currDateStoreTZ,
                  source,
                  food.visiblityOverrideId,
                  visibilityOverrides,
                  false,
                  section.visiblityOverrideId
                )
          ){
            delete section.foods[food_idx];
            continue;
        }
        food["section_idx"] = section_idx;
        food["food_idx"] = food_idx;

        if(food["modifiers"]){
          let modifierGroup = {};
          modifierGroup["modifiers"] =[];
          for(let mod of food["modifiers"]){
            let modf = {...modifiers[mod]};
            modifierGroup["modifiers"].push(modf);
          }
          food["modifierGroup"] = modifierGroup;
        }

        if (! ("station" in food)) {
          if ("station" in section) {
            food["station"] = section["station"];   
          } else if (defaultStations){
            food["station"] = defaultStations;
          }
        }
        if (food['id'] in popularItems){
          popularSection['foods'][popularItems[food['id']]] = food;
        }
      }
      if (Object.keys(section.foods).length === 0){
        delete menu[section_idx];
      }
    }
    if (popularSection['foods'].length > 0 ) {
      menu = [popularSection, ...Object.values(menu)];
    }
    return menu;
  }

  function getRestauNameAndMenu(restauId, source){
    if (restauId === "None") {
      return;
    }
    const database = window.firebase.database();
    var menuRef = database.ref("menus/"+restauId);
    //let listener = 
    menuRef.once('value').then(function(snapshot) {
      var result = snapshot.val();
      if (!(result)) {
        return;
      }
      setRestauName(result.restaurantName || 'None');
      if ('bannerUrl' in result) {
        setBannerUrl(result.bannerUrl);
      }
      if ('bannerUrlDesktop' in result) {
        setBannerUrlDesktop(result.bannerUrlDesktop);
      }

      var enhancedMenu = enhanceMenuWithStationAndModifiersAndOverrides(
        result.menu,
        result.popularItems,
        result.storeTimezone,
        source,
        result.defaultStations,
        result.modifiers,
        result.visibilityOverrides
    );
      setMenu(enhancedMenu);

      setTemplate(result.template);

      if (result.payments){
        setSalesTax(result.payments.salesTax);
        if (result.payments.serviceCharge){
          setServiceCharge(result.payments.serviceCharge);
        }
      }
      if(result.promotionMessages){
        setPromotionMessages(result.promotionMessages)
      }
      // for backward compatibility.
      if (result.salesTax){
        setSalesTax(result.salesTax);
      }

      if (result.details && result.details.hours) {
        setRestauHours(result.details.hours);
      }

      if (result.details && result.details.oob_no_order_dine_in) {
        setOobNoOrder(result.details.oob_no_order_dine_in);
      }
    
    
    });

  }

  //https://stackoverflow.com/questions/51902759/remove-off-for-firebase-listeners
  function detachListener(){
    console.log(restauTableKey);
    console.log(listener);
    console.log(orderRef);
    database.ref("orders/"+restauTableKey).off();
    database.ref("activeusers/" + restauId + "/" + tableId).off();
    database.ref("activeusers/" + restauId + "/" + tableId + "/checkPlease").off();
    database.ref("activeusers/" + restauId + "/" + tableId + "/helpPlease").off();
    // orderRef.off();
    // activeUserRef.off();
  }

  function selfCheckIn(rid, tid, name){
    console.log("Calling selfCheckIn ");
    var checkinRef = database.ref("activeusers/" + rid + "/" + tid + "/checkins").push();
    var val = {key: checkinRef.key, name: name}
    checkinRef.set(val);
    dispatchCheckin({type: "ADD_SELF", payload: checkinRef.key});

    var checkinHistory = database.ref("checkinhistory/" + rid + "/" + checkinRef.key)
    checkinHistory.set({key: checkinRef.key, name: name, tableId: tid, userAgent: window.navigator.userAgent, time: getDateTime()});
  }

  useEffect(() => {
    
    var result = getRestauAndTableId();
    if (result['restauId'] === 'None' || !result['tableId'] || result['tableId'] === 'None'){
      return;
    }
    setOrderDBListener(result['restauTableKey']);
    if (name != ''){
      selfCheckIn(result['restauId'], result['tableId'], name );
      setActiveUsersDBListener(result['restauId'], result['tableId']);
    }
    getRestauNameAndMenu(result['restauId'], "DineIn");
  }, []);

  useEffect(() => {
    setActiveUsersDBListener(restauId, tableId);
    localStorage.setItem('nameInLocalStorage', name);
  }, [name]);

  useEffect(() => {
    if (forceDetach || staleCheckin){
      detachListener();
      setDisableOrdering(true);
      setDisableHelp(true);
    }
  }, [forceDetach, staleCheckin]);

  useEffect(() => {
    if (checkin.staleCheckin){
      setStaleCheckin(true);
      dispatchOrder({type: "RESET"});
      //setOrders({});
      setDisableOrdering(true);  
      setDisableHelp(true);
    }
  }, [checkin]);

  return {
    restauId,
    tableId,
    restauTableKey,
    orders,
    detachListener,
    restauName,
    menu,
    checkPlease,
    forceDetach,
    staleCheckin,
    numberOfCheckins, 
    disableOrdering,
    template,
    reviews,
    dispatchReviews,
    salesTax,
    serviceCharge,
    getRatings,
    setOrderHistoryId,
    orderHistoryId,
    helpPlease, 
    disableHelp,
    enqueueSnackbar,
    checkinId,
    dispatchCheckin,
    tip,
    setTip,
    name,
    setName,
    bannerUrl,
    bannerUrlDesktop,
    promotionMessages,
    restauHours,
    oobNoOrder,
    outsideBizHours,
    setOutsideBizHours
  };
}
