import React from "react";
import styled from "styled-components";
//import { foods } from "../Data/FoodData";
import { Food, FoodGrid, FoodLabel } from "./FoodGrid";
import { formatPrice } from "../Data/FoodData";
import Collapsible from "./Collapsible"
import { DialogContent } from "../FoodDialog/FoodDialog";

//import Collapsible from 'react-collapsible';

//  height: 1000px;
const MenuStyled = styled.div`
  margin: 0px 400px 50px 20px;
  // Add this part
  @media(max-width: 720px){ 
    width: 80%; 
    margin: auto; 
    margin-bottom: 40px;

  }
`;

const MenuContent = styled(DialogContent)`
  padding: 20px;
  height: 100%;
`;

//Collapsible trigger={sectionName}
const BannerMessageDiv=styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;`;

export function Menu({ setOpenFood, menu ,promotionMessages}) {
  return (
    <MenuStyled>
      {(promotionMessages && promotionMessages.banner_message) && <BannerMessageDiv style={{color: 'green'}}>{promotionMessages.banner_message}</BannerMessageDiv>}
      <MenuContent>
      {menu && menu.map((section, sidx) => (
        section ?
        <Collapsible key={section.id} trigger={section.name} open={sidx === 0}>
          <FoodGrid>
            {section.foods && section.foods.map((food, fidx) => ( 
              food ?
              <Food key={section.id+food.id} oos={food.oos}
                img={food.img}
                onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                  setOpenFood(food);
                }}
              >
                <FoodLabel img={food.img}>
                  <div style={{marginRight:'20px'}}><span opacity="0.5">{food.name} </span>{food.oos ? <span style={{backgroundColor: "#e74c3c", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}</div>
                  <div>{food.pricelabel? food.pricelabel: formatPrice(food.price/100) }</div>
                </FoodLabel>
              </Food> : <div key={sidx+ ' ' + fidx}/>
            ))}
          </FoodGrid>
        </Collapsible> : <div key={sidx}/>
      ))}
      </MenuContent>
    </MenuStyled>
  );
}
