import React from "react";
import styled from "styled-components";
import { Title } from "../Styles/title";
import { pizzaRed } from "../Styles/colors";
import { Notes } from "../Menu/MenuWrapper";

// Styling a regular HTML input
//  width: 24px;
// const StyledInput = styled.input`
//   display: block;
//   margin: 20px 0px;
//   border: 1px solid lightblue;
// `;

const StyledInput = styled.input`
  display: block;
  margin: 0px 10px;
  
`;

/*  @media(max-width: 720px){ 
    margin-bottom: 40px;
  }
*/
const StyledTextArea = styled.textarea`
  margin-top: 10px; 
  font-size: 16px;
  rows={2}
  cols={100}
  width: 100%;
  height: 56px;
  left: 20px;
  top: 635px;
  margin-right: 20px;
  border: 2px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;

`;

/*
  @media(max-width: 720px){ 
    padding-bottom: 50px;
  }
*/
const NotesContainer = styled(Title)`
  margin: 20px 0px 20px;
  padding-bottom: 10px;

`;
export const StyledTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #222C31;
`;


export function NotesInput(props) {
  return (
    <NotesContainer>
      <StyledTitle><b>{props.label}</b></StyledTitle>
      <span style={{display: "block"}} />
      <StyledTextArea {...props} />
      {props.specialInstructionDisclaimer && 
        <Notes style={{textAlign: 'left'}}>{props.specialInstructionDisclaimer}</Notes>
      }
    </NotesContainer>
  );
}
