import { useState } from "react";
import cloneDeep from 'lodash/cloneDeep';

export function useModifiers(defaultChoice) {
  const [value, setValue] = useState(cloneDeep(defaultChoice));//{modifiers: [{modifier}]}

 
  function optionOnChange() {
    let val = value;
    setValue({ ...val });
  }
  function changeRadio(modifierId, optionId) {
    const newModifierGrp = {...value, modifiers: value.modifiers.map((modifier) => {
      if(modifier.id == modifierId) {
        return {... modifier, options: modifier.options.map((opt)=> {
          return {... opt, checked: opt.id == optionId}
        })};
      } else return modifier;
    })};
    setValue(newModifierGrp);
  }

  function changeCheckBox(modifierId, optionId) {
    const newModifierGrp = {...value, modifiers: value.modifiers.map((modifier) => {
      if(modifier.id == modifierId) {
        return {... modifier, options: modifier.options.map((opt)=> {
          return opt.id == optionId ? {... opt, checked: !opt.checked} : opt
        })};
      } else return modifier;
    })};
    setValue(newModifierGrp);
  }
  return {
    value,
    setValue,
    optionOnChange,
    changeRadio,
    changeCheckBox
  };
}
