import React from 'react';
import styled from "styled-components";
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';

export const CartButton = styled(Title)`
  color: white;
  font-weight: bold;
  border-radius: 50px;
  padding: 10px;
  position: fixed;
  bottom: 15px;
  text-align: center;
  width: 200px;
  left: calc(50% - 100px);
  cursor: pointer;
  background-color: ${corianderGreen};
  ${({ disabled }) =>
    disabled &&
    `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export function ViewCart({toggleShowOrders, orders}){
  function onCartClick() {
    toggleShowOrders();
  }

  var draftOrders = 0;
  for (const key in orders) {
    if (orders[key].status === "DRAFT") {
      draftOrders += 1;
    }
  }

  var msg = "View tab";

  if (draftOrders) {
    //msg = "Submit to tab";
    msg = draftOrders;
  }

  return Object.keys(orders).length === 0 ? <div/>: 
  <>
        <CartButton onClick={onCartClick}>
          {/* <span role="img" aria-label="Cart">{msg}</span> */}
          <span role="img" aria-label="Cart" style={{"fontWeight": "bold"}}> 
            {draftOrders ? <i className="fas fa-cart-plus"> </i> : <></>}
            {"  "}
            {msg} 
          </span>

        </CartButton>
  </>

}