import React from "react";
import styled from "styled-components";
import { formatPrice,isDiscountedPrice } from "../../Data/FoodData";
import Collapsible from "../Collapsible";
//import { foods } from "../Data/FoodData";


const DialogContent = styled.div`
  overflow: auto;
  min-height: 20px;
  //padding: 0px 40px;
  //padding-bottom: 20px;
`;

const StrikePrice =styled.span`
  font-size: .875rem;
  color: red;
  margin-left:5px;
  text-decoration: line-through;
  `;

const MenuContent = styled(DialogContent)`
  //padding: 20px;
  height: 100%;
`;

const FoodGrid = styled.div`
  @media(max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  @media(max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 20px;
`;

  // ${props => props.img !== undefined && `
  //   position: absolute;
  //   justify-content: space-between;
  // `}

  //  background-color: rgba(255, 255, 255, 0.8);
  // padding: 5px;
const FoodLabel = styled.div`
  ${props => props.img !== undefined ? 
    `position: absolute;` :
    `justify-content: space-between;   display: flex;`
  }

`;

  
const FoodContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${props => props.oos ? "#c0392b11" : "white" }

    &:hover {
      cursor: pointer;
      background-color: ${props => props.oos ? "#c0392b11" : "#f8f8f8" };
    }
`;

const Description = styled.div`
  color: gray;
  font-size: 13px;
  margin-top: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;


export default function Menu2Template({ setOpenFood, menu, showCount }) {

  return (
    <MenuContent>
      {menu && Object.entries(menu).map(([sidx, section]) => (
        section ?
        <Collapsible key={section.id} trigger={section.name + (showCount? ' ('+Object.entries(section.foods).length+')' : '')} open={sidx === "0"}>
          <FoodGrid>
            {section.foods && Object.entries(section.foods).map(([fidx, food]) => (
              food ?
              <FoodContainer key={section.id+food.id} oos={food.oos}
                
                onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                  setOpenFood(food);
                }}
              >
                <FoodLabel >
                  <div style={{marginRight:'20px'}}><span opacity="0.5"><b>{food.name}</b> </span>{food.oos ? <span style={{backgroundColor: "#e74c3c", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}</div>
                  <div>{food.pricelabel? food.pricelabel: formatPrice(food.price/100) } {isDiscountedPrice(food.original_price,food.price) &&<StrikePrice>{formatPrice(food.original_price/100)}</StrikePrice>}</div>
                  {/* <div>{food.pricelabel? food.pricelabel: formatPrice(food.price)}</div> */}
                </FoodLabel>
                <Description>{food.desc}</Description>
              </FoodContainer> : <div key={sidx+ ' ' + fidx}/>
            ))}
          </FoodGrid>
        </Collapsible> : <div key={sidx}/>
      ))}
      </MenuContent>
  );
}
