import styled from "styled-components";
import React from "react";
import { formatPrice } from "../Data/FoodData";
import {LabelContainer} from "./FoodDialog"


const ToppingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // Add this part
  @media(max-width: 720px){ 
    grid-template-columns: 1fr;
  }
`;

const ToppingDiv = styled.div`
  margin-top: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #222C31;
`;

const ToppingCheckbox = styled.input`
  margin-right: 10px;
  cursor: pointer;
  margin-top: 5px;
  border: 5px solid;
  vertical-align: middle;
  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5; 
     pointer-events: none; 
     `}
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
`;

export function Toppings({ toppings, checkTopping, openFood }) {
  var maxNumberOfSelectionReached = false;
  if (openFood.toppingsmax){
    let checkedCount = 0;
    for(let topping of toppings){
      if (topping.checked){
        checkedCount += 1;
      }
   }
   if (checkedCount >= openFood.toppingsmax){
    maxNumberOfSelectionReached = true;
   }
  }

  return (
    <ToppingDiv>
      {openFood.toppingslabel ? 
        <LabelContainer style={{paddingBottom: "3px"}}><b>{openFood.toppingslabel}</b></LabelContainer> :
        <LabelContainer style={{paddingBottom: "3px"}}><b>Options:</b> </LabelContainer>}
      <ToppingGrid>
        {toppings.map((topping, i) => (
          <CheckboxLabel>
            <ToppingCheckbox
              type="checkbox"
              checked={topping.checked}
              disabled={maxNumberOfSelectionReached && !topping.checked}
              onClick={() => {
                checkTopping(i);
              }}
            />
            {topping.name}
            {topping.price ? (<span> (+{formatPrice(topping.price/100)})</span>) : "" }
          </CheckboxLabel>
        ))}
      </ToppingGrid>
    </ToppingDiv>
  );
}
