import React from "react";
import styled from "styled-components";
//import { foods } from "../Data/FoodData";
import { formatPrice } from "../Data/FoodData";
import Collapsible from "./Collapsible"
import { DialogContent } from "../FoodDialog/FoodDialog";
import {RightAlignPrice } from "../Order/Order"

//import Collapsible from 'react-collapsible';

//  margin: 0px 400px 50px 20px;
//  height: 1000px;
const MenuStyled = styled.div`
margin: 50px 100px 100px 100px;

  @media(max-width: 1000px){ 
    margin: 50px 50px 100px 50px;
  }
  // Add this part
  @media(max-width: 720px){ 
    width: 100%; 
    margin: auto; 
    margin-bottom: 100px;
    margin-top: 50px;
  }



`;

const MenuContent = styled(DialogContent)`
  padding: 20px;
  height: 100%;
`;

const FoodGrid = styled.div`
  @media(max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  @media(max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  padding-bottom: 20px;
`;

  // ${props => props.img !== undefined && `
  //   position: absolute;
  //   justify-content: space-between;
  // `}

  //  background-color: rgba(255, 255, 255, 0.8);
  // padding: 5px;
  //justify-content: space-between;
const FoodLabel = styled.div`
  ${props => props.img !== undefined ? 
    `position: absolute;` :
    `display: grid;
     grid-template-columns: 94px auto ;
     grid-gap: 10px;
     `
  }
`;
  
const FoodContainer = styled.div`
@media(max-width: 500px) {
  padding: 7px 0px;
}
  padding: 12px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${props => props.oos ? "#c0392b11" : "white" }

    &:hover {
      cursor: pointer;
      background-color: ${props => props.oos ? "#c0392b11" : "#f8f8f8" };
    }
`;

const Description = styled.div`
  color: gray;
  font-size: 13px;
  margin-top: 5px;
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const Tagline = styled.div`
      color:#333333 100%;
      opacity: 0.5;
      font-size: 20px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      fontWeight: bold;
`;

const Notes = styled.div`
      color:#333333 100%;
      opacity: 0.5;
      font-size: 12px;
      word-spacing:0px;
      text-align: center;
      fontWeight: bold;
`;

const Hours = styled.div`
      color: #27ae60;
      font-size: 15px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
      fontWeight: bold;
`;

export const daysOfTheWeek = 
                      {0: 'Sunday',
                      1: 'Monday',
                      2: 'Tuesday',
                      3: 'Wednesday',
                      4: 'Thursday',
                      5: 'Friday',
                      6: 'Saturday'};

const PauseMessage = styled.div`
      color: #e74c3c;
      font-size: 15px;
      word-spacing:0px;
      text-align: center;
      margin-top: 10px;
      fontWeight: bold;
`;

//Collapsible trigger={sectionName}

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
`

const CurrentlyClosed = styled.div`
    background-color: #e74c3c; 
    color: #FFFFFF;
    border-radius: 5px;
`

const ImageDetails = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;
const BannerMessageDiv=styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;`;

export function MenuImageLeft({ setOpenFood, menu,promotionMessages}) {
  
  return (
    <MenuStyled>
      {(promotionMessages && promotionMessages.banner_message) && <BannerMessageDiv style={{color: 'green'}}>{promotionMessages.banner_message}</BannerMessageDiv>}
      <MenuContent>
      {menu && menu.map((section, sidx) => (
        section ?
        <Collapsible key={section.id} trigger={section.name} open={sidx === 0}>
          <FoodGrid>
            {section.foods && section.foods.map((food, fidx) => ( 
              food ?
              <FoodContainer key={section.id+food.id} oos={food.oos}
                
                onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                  setOpenFood(food);
                }}
              >
                <FoodLabel >   
                  {/* style={{justifyContent:"center", display:"flex"}} */}
                    {food.img ? <img src={food.img} alt={food.name} style={{"width": "94px", "height": "94px", "objectFit": "cover", "borderRadius": "5%"}} /> 
                      : <img src="/menu-images/stock_img.jpg" alt={food.name} style={{"width": "94px", "height": "94px", "objectFit": "cover", "borderRadius": "5%"}} />}
                  <ImageDetails>
                    <Title>
                      <span opacity="0.5"><b>{food.name} </b></span>
                      {food.oos ? <span style={{backgroundColor: "#e74c3c", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}
                    </Title>
                    <Description>{food.desc}</Description>
                    <div style={{paddingLeft:"10px",paddingTop:"5px", fontSize:"15px"}}>{food.pricelabel? food.pricelabel: formatPrice(food.price/100) }</div>
                  </ImageDetails>

                </FoodLabel>
                {/* <Description>{food.desc}</Description> */}
              </FoodContainer> : <div key={sidx+ ' ' + fidx}/>
            ))}
          </FoodGrid>
        </Collapsible> : <div key={sidx}/>
      ))}
      </MenuContent>
    </MenuStyled>
  );
}
