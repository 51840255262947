import React from "react";
import { formatPrice } from "../../Data/FoodData";
import FoodSoldOut from "../../Menu/Templates/FoodSoldOut";
import { ModifierDiv, LabelContainer, ModifierGrid, RadioInput } from "./ModifierStyle";
import {getQuatity} from "./ModifierUtil";
export default function ModifierRadio({ modifier, onChange }) {

    return <ModifierDiv>
        <LabelContainer><b>{modifier.label}</b></LabelContainer>
        <ModifierGrid >
            {modifier.options.map((option, i) => !option.hide &&
            (
                <>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>

                        <RadioInput
                            type="radio"
                            id={option.id}
                            name={modifier.id}
                            value={option.label}
                            checked={option.checked ? option.checked : false}
                            onClick={(e) => { onChange(modifier.id, option.id) }}
                            disabled={option.oos}
                        />
                        <span>
                            {option.label}
                            {option.price && option.price>0 ?(" (+" + formatPrice((option.price * getQuatity(option.quantity)) / 100) + ") "):<></>}
                        </span>
                        {option.oos ? <FoodSoldOut /> : ''}
                    </label>
                </>
            ))}
        </ModifierGrid>
    </ModifierDiv>
}