import stageConfig from './stage'
import prodConfig from './prod'
import devConfig from './dev'


let Config ;
let env_value = "";
if(window.location.hostname.includes("localhost") || window.location.hostname.includes("192.168")){
    env_value = "dev";
    Config = devConfig;
}else if(window.location.hostname.includes("stage") || window.location.hostname.includes("8644d")){
    env_value = "stage";
    Config = stageConfig;
}else{
    env_value = "prod";
    Config = prodConfig;
}
console.log(env_value);
export default  Config; 