 
 
let cloud_function_url = "http://localhost:5001/sliceline-takeout-stage-4bf89/us-central1/"

//comment below to hit local server
cloud_function_url = "https://us-central1-sliceline-takeout-stage-4bf89.cloudfunctions.net/"

const devConfig = {
    environment: "Dev-",
    stripeConfig : "pk_test_51HrjUNHaof0IjCDr8uObDLPZEA0HLwx9QlWMUznvzoFbPey1QKbHuULuvmykdy51zgMMo24CSfhWqh7EfZmdto6X00ZiQaZ4Xr",

    firebaseConfig: {
      appId: "1:856193594445:web:e4515098abad087c48d804",
      apiKey: "AIzaSyCDvtBiowtLtnulX9VYRPUSHJFgWQH-0N8",
      authDomain: "sliceline-takeout-stage-4bf89.firebaseapp.com",
      databaseURL: "https://sliceline-takeout-stage-4bf89-default-rtdb.firebaseio.com/",
      projectId: "sliceline-takeout-stage-4bf89",
      storageBucket: "sliceline-takeout-stage-4bf89.appspot.com",
      messagingSenderId: "856193594445",
      measurementId: "G-169KN2X4E8"
    },

    create_checkout_session_url: cloud_function_url+"create_checkout_session",
    get_order_details_url: cloud_function_url+"get_order_details",
    create_paylater_order_url: cloud_function_url+"create_paylater_order",
    make_payment_url: cloud_function_url+"make_pickup_payment",
    delete_dinein_item_url: cloud_function_url+"deleteDineInItem",
    dinein_call_check_url: cloud_function_url+"dineInCallCheck",
    dinein_place_order_url: cloud_function_url+"dineInPlaceOrder",
    
    };

    export default devConfig;