import React from "react";
import styled from "styled-components";
import { FoodLabel } from "../Menu/FoodGrid";
import { corianderGreen } from "../Styles/colors";
import { Title } from "../Styles/title";
import { formatPrice } from "../Data/FoodData";
import { QuantityInput } from "./QuantityInput";
import { useQuantity } from "../Hooks/useQuantity";
import { Toppings } from "./Toppings";
import { useToppings } from "../Hooks/useToppings";
import { useChoice } from "../Hooks/useChoice";
import { NotesInput } from "./NotesInput";
import { useNotes } from "../Hooks/useNotes";
import { Choices } from "./Choices";
import { getDateTime }  from '../Hooks/useOrders';
import {ModifierGroup}  from "./ModifierGroup/ModifierGroup";
import {validateOrderSelection,getModifierPrice,getModifierStr} from "./ModifierGroup/ModifierUtil";
import { useModifiers } from "../Hooks/useModifiers";
import { Notes } from "../Menu/MenuWrapper";


export const Dialog = styled.div`
 
  width: 500px;
  background-color: white;
  position: fixed;
  top: 75px;
  z-index: 20;
  max-height: calc(100% - 175px);
  left: calc(50% - 250px);
  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  @media(max-width: 720px){ 
    max-height: 90%;
    height: 90%; //iphone
    left: 0px;
    top: 0px;
    overflow-y: auto important!;
    width: 100%; 
    z-index: 9998!important;
    -webkit-overflow-scrolling: touch !important;
  }

`;

// overflow: auto;
// min-height: 20px;
// padding: 0px 40px;
// padding-bottom: 20px;

export const DialogContent = styled.div`

overflow: auto;
  
min-height: 20px;
padding: 0px 20px;
margin-bottom: 50px;
padding-bottom: 50px;

@media(max-width: 720px){ 
  overflow:visible;
}
`;

// export const DialogFooter = styled.div`
//   box-shadow: 0px -2px 0px 0px #e7e7e7;
//   display: flex;
//   justify-content: center;
//   min-height: 60px;
// `;

export const DialogFooter = styled.div`
  box-shadow: 0px -2px 0px 0px #e7e7e7;
  display: flex;
  justify-content: center;
  min-height: 60px;
  background-color: white;  
  @media(max-width: 720px){ 
    position: fixed;
    bottom: 10%;
    width: 100%;
}
`;

//  height: ${props => props.ht ?  props.ht : "20px" };
export const ConfirmButton = styled(Title)`
  margin: 10px;
  color: white;
  font-weight: bold;
  font-size : 18px;
  border-radius: 5px;
  padding: 7px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  cursor: pointer;
  background-color: ${corianderGreen};
  ${({ disabled }) =>
    disabled &&
    `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 19;
`;

//   margin-bottom: 20px;
const DialogBanner = styled.div`
  min-height: 200px;
  ${({ img }) => (img ? `background-image: url(${img});` : `min-height: 50px;`)}
  background-position: center;
  background-size: cover;
  @media(max-width: 720px){ 
    min-height: 170px;
  }
`;

//  margin-right : 5px;

const CloseButton = styled.button`
  position: absolute;
  right : 0;
  top : 0;
  margin-top : 5px;
  border-radius : 1rem;
  background-color: transparent;
  border: none;
  z-index : 9999;
`;

const CloseImg = styled.img`
  width: 2rem;
  height: 2rem;
`;

// const DialogBannerName = styled(FoodLabel)`
//   font-size: 30px;
//   padding: 5px 40px;
//   top: ${({ img }) => (img ? `100px` : `20px`)};
// `;


const DialogBannerName = styled(FoodLabel)`
  padding: 10px 20px 10px;
  box-shadow: 0px 2px 0px 0px #e7e7e7;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 150% */
  position: relative;
  z-index: 99 !important;
  letter-spacing: -0.01em;

  color: #222C31;
`;

// const pricePerTopping = 0.5;
// export function getPrice(order) {
//   return (
//     order.quantity *
//     (order.price +
//       order.toppings.filter(t => t.checked).length * pricePerTopping)
//   );
// }

export function getPriceWithToppings(order) {
  var toppingsPrice = 0;
  if (order.toppings) {
    for (var t of order.toppings){
      if (t.checked) {
        if ("price" in t) {
          toppingsPrice += t.price;
        }
      }
    }
  }
  return (
    order.price + toppingsPrice
  );
}

export function getPrice(order) {
  return (
    order.quantity *
   (getPriceWithToppings(order)+getModifierPrice(order))
  );
}

export function getToppingsStr(order){
  if (!order || !order.toppings){
    return null;
  }
  let toppingsStr = order.toppings
  .filter(t => t.checked)
  .map(topping => topping.name)
  .join(", ");
  if (toppingsStr !== "") {
    return toppingsStr;
  }else{
    return null;
  }
}

const FoodDescription = styled.div`
  display: flex;
  padding: 20px 0px 0px;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #7C7D7D;
`;


export const LabelContainer = styled(Title)`
  display: flex;
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #222C31;
`;



function FoodDialogContainer({ openFood, setOpenFood, disableOrdering, restauTableKey, tableId, name, promotionMessages}) {
  const quantity = useQuantity(openFood && openFood.quantity);
  const toppingsHook = useToppings(openFood.toppings);
  const choiceRadio = useChoice(openFood.choice);
  const notes = useNotes(openFood.notes);
  const modifiersHook = useModifiers(openFood.modifierGroup);

  const isEditing = openFood.index > -1;

  function close() {
    setOpenFood();
  }

  const order = {
    ...openFood,
    quantity: quantity.value,
    toppings: toppingsHook.toppings,
    choice: choiceRadio.value,
    notes: notes.value,
    modifierGroup:modifiersHook.value
  };

  if ("choices" in order){
    for (var c of order.choices) {
      if (c.name === order.choice){
        order.choiceId = c.id;
        if ("price" in c){
          order.price = c.price;
        }
      }
    }
  }

  // Needs to change
  function editOrder() {
    if(!validateOrderSelection(order)){
      return;
    }
    const database = window.firebase.database();

    var newOrder = {
      "id": order.id,
      "quantity": order.quantity,
      "name" : order.name,
      "status": order.status,
      "price": order.price,
      key: order.key,
      tableId : order.tableId,
      notify: true,
      orderType: "Dine In",
      addedBy: order.addedBy,
      updatedBy: name,
      added_timestamp : order.added_timestamp,
      updated_timestamp : getDateTime()
    };


    if (order.station) {
      newOrder['station'] = order.station;
    }
    if (order.img) {
      newOrder['img'] = order.img;
    }
    if (order.desc) {
      newOrder['desc'] = order.desc;
    }

    if (order.toppings){
      let toppingsStr = order.toppings
        .filter(t => t.checked)
        .map(topping => topping.name)
        .join(", ");
      if (toppingsStr !== "") {
        newOrder['toppingsStr'] = toppingsStr;
      }
      newOrder['toppings'] = order.toppings;
    }

    if (order.choice){
      newOrder['choiceId'] = order.choiceId;
      newOrder['choice'] = order.choice;
      newOrder['choices']= order.choices;
    }

    if (order.notes && order.notes !== ''){
      newOrder['notes'] = order.notes;
    }

    if(order.modifierGroup){
      newOrder['modifierGroup'] = order.modifierGroup;
      newOrder['modifiersStr'] = getModifierStr(order);
    }

    var updates = {}; 
    updates["orders/"+restauTableKey+"/" + order.key] = newOrder;
    database.ref().update(updates);
    close();
  }

  function addToOrder() {
    if(!validateOrderSelection(order)){
      return;
    }
    const database = window.firebase.database();
    var newOrderRef = database.ref("orders/"+restauTableKey).push();
    // order['toppings'] = order.toppings.filter(t => t.checked)
    //               .map(topping => topping.name)
    //               .join(", ")

    var newOrder = {
      "id": order.id,
      "quantity": order.quantity,
      "name" : order.name,
      "status": "DRAFT",
      "price": order.price,
      key: newOrderRef.key,
      tableId : tableId,
      notify: true,
      orderType: "Dine In",
      addedBy: name,
      added_timestamp : getDateTime()
    };

    if (order.station) {
      newOrder['station'] = order.station;
    }

    if (order.img) {
      newOrder['img'] = order.img;
    }
    if (order.desc) {
      newOrder['desc'] = order.desc;
    }

    if (order.toppings){
      let toppingsStr = order.toppings
        .filter(t => t.checked)
        .map(topping => topping.name)
        .join(", ");
      if (toppingsStr !== "") {
        newOrder['toppingsStr'] = toppingsStr;
      }
      newOrder['toppings'] = order.toppings;
    }

    if (order.choice){
      newOrder['choiceId'] = order.choiceId;
      newOrder['choice'] = order.choice;
      newOrder['choices']= order.choices;
    }

    if (order.notes && order.notes !== ''){
      newOrder['notes'] = order.notes;
    }

    if(order.modifierGroup){
      newOrder['modifierGroup'] = order.modifierGroup;
      newOrder['modifiersStr'] = getModifierStr(order);
    }

    newOrderRef.set(newOrder);
    //setOrders([...orders, order]);
    close();
  }

  return (
    <>
      <DialogShadow onClick={close} />
      <Dialog>
        <CloseButton onClick={close}><CloseImg src="/circle_x.svg"/></CloseButton>
        {openFood.img && <DialogBanner img={openFood.img}>
        </DialogBanner>}
        <DialogBannerName>
          {/** adding right margin so name doesnt go over close button */}
          <div style={{marginRight:'30px'}}><b> {openFood.name} </b> </div>
        </DialogBannerName>

        {/* <DialogBanner img={openFood.img}>
          <DialogBannerName> {openFood.name} </DialogBannerName>
        </DialogBanner> */}
        
        <DialogContent>
          {openFood.desc && (<FoodDescription>{openFood.desc}</FoodDescription>)}

          <QuantityInput quantity={quantity} />
          
          {openFood.choices && (
            <Choices key="choiceKey" openFood={openFood} choiceRadio={choiceRadio}/>
          )}

          {openFood.toppings && (
              <Toppings key="toppingsKey" {...toppingsHook} openFood={openFood} />
          )}

          {openFood.modifierGroup && (
              <ModifierGroup modifierHook={modifiersHook}/>
          )}

        <NotesInput {...notes} specialInstructionDisclaimer={promotionMessages.special_instruction_disclaimer} placeholder="allergies, spicy, etc" label="Special Instructions:"></NotesInput>
        {/* <NotesContainer>Notes:</NotesContainer>
        <StyledInput
          {...notes}
          placeholder="Type in here"
        /> */}
        {(promotionMessages && promotionMessages.food_allergy_warning) &&
          <Notes style={{textAlign: 'left'}}>
            <span style={{color : "red"}}>FOOD ALLERGY WARNING :&nbsp;</span>
            {promotionMessages.food_allergy_warning}
          </Notes>
        }
        {/** Below stmt is working in some cases in other its not. TODO: clean up this hack.*/}
        <div style={{minHeight: '50px', marginBottom: '50px', paddingBottom: '50px'}}>&nbsp;</div>
        </DialogContent>
        <DialogFooter>
          <ConfirmButton
            onClick={isEditing ? editOrder : addToOrder}
            disabled={disableOrdering || (openFood.choices && !choiceRadio.value)}
          >
            {isEditing ? `Update order ` : `Add to order `}
            {order.price ? formatPrice(getPrice(order)/100) : ''}
          </ConfirmButton>
          <ConfirmButton
            onClick={close}
            style={{width:"80px"}}
          >
             Close
          </ConfirmButton>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export function FoodDialog(props) {
  if (!props.openFood) return null;
  return <FoodDialogContainer {...props} />;
}
