import React from 'react';
import {Dialog, DialogContent, DialogShadow, DialogFooter, ConfirmButton} from "../FoodDialog/FoodDialog";
import styled from "styled-components";
import {useChoice} from "../Hooks/useChoice";
import {useNotes} from "../Hooks/useNotes";
import { getDateTime } from '../Hooks/useOrders';

const HelpDialogBody = styled(Dialog)`
@media(max-width: 720px){ 
  width: 100%; 
  left: 0px; 
  z-index: 22; 
}
z-index : 22;
`;

const HelpDialogShadow = styled(DialogShadow)`
z-index : 21;
`;

export const RadioInput = styled.input`
cursor: pointer;  
margin-right: 10px;
`;

export function HelpDialog({openHelpDialog, setOpenHelpDialog, restauId, tableId, disableHelp, helpPlease, checkinId, name}){
  const reasonRadio = useChoice('');
  const reasonOther = useNotes();

  const database = window.firebase.database();

  function close() {
    setOpenHelpDialog(false);
  }
  function callForHelp() {
    var reason = "";
    if (reasonRadio.value){
      reason = reasonRadio.value;
    }
    if (reasonOther.value){
      reason += " " + reasonOther.value;
    }

    var updates = {}; 
    updates["summons/"+restauId+"/" + tableId + "/help"] = true;
    updates["summons/"+restauId+"/" + tableId + "/help_timestamp"] = getDateTime();
    updates["summons/"+restauId+"/" + tableId + "/helpActionBy"] = name;
    updates["summons/"+restauId+"/" + tableId + "/helpReason"] = reason.trim();
    updates["summons/"+restauId+"/" + tableId + "/tableId"] = tableId;
    updates["activeusers/"+restauId+"/" + tableId + "/helpPlease"] = {status: true, actionBy: name}; 
    database.ref().update(updates);
  }

  function turnOffHelp() {
    var updates = {}; 
    updates["summons/"+restauId+"/" + tableId + "/help"] = false;
    updates["summons/"+restauId+"/" + tableId + "/helpActionBy"] = name;
    updates["activeusers/"+restauId+"/" + tableId + "/helpPlease"] = {status: false, actionBy: name}; 
    database.ref().update(updates);
  }

  return openHelpDialog ? <>
    <HelpDialogShadow onClick={close} />
    <HelpDialogBody>
      {helpPlease == false ? 
      <><DialogContent>
        <h2>Can I help you? </h2>
        <p>
          If you need your host's attention, click on help. Your host will be at your table soon.
          <br/><br/>
          [Optional] <br/>  
          Help needed for:
          <br/>  
          <div>
            <label>
              <RadioInput
                type="radio"
                id="reason"
                name="reason"
                value="Water Refill."
                checked={reasonRadio.value === "Water Refill."}
                onChange={reasonRadio.onChange}
              />
                Water Refill
            </label> 
          </div>
          <div>
            <label>
              <RadioInput
                type="radio"
                id="reason"
                name="reason"
                value="Silverware/Napkin."
                checked={reasonRadio.value === "Silverware/Napkin."}
                onChange={reasonRadio.onChange}
              />
                Silverware/Napkins
            </label> 
          </div>
          <div>
            <label>
              <RadioInput
                type="radio"
                id="reason"
                name="reason"
                value="Order Related."
                checked={reasonRadio.value === "Order Related."}
                onChange={reasonRadio.onChange}
              />
                Order Related
            </label> 
          </div>
          <div>
            <label>
              <RadioInput
                type="radio"
                id="reason"
                name="reason"
                value="Other."
                checked={reasonRadio.value === "Other."}
                onChange={reasonRadio.onChange}
              />
            Other
            </label> 
          </div>
          <br/>
          <textarea
              value={reasonOther.value}
              placeholder="Comment"
              onChange={reasonOther.onChange}
              rows={4}
              cols={25}
            />          
        </p>
      </DialogContent>
      <DialogFooter >
        <ConfirmButton disabled={disableHelp} onClick={() => {
          callForHelp();
          close();
        }}>
          {/* He🙋p */}
          Help
        </ConfirmButton>
      </DialogFooter></> :

      <><DialogContent>
      <h2>Help is on the way .. </h2>
      <p>
        If you no longer need help, click on Cancel. 
      </p>
      </DialogContent>
      <DialogFooter>
      <ConfirmButton disabled={disableHelp} onClick={() => {
        turnOffHelp();
        close();
      }}>
        Cancel
      </ConfirmButton>
      </DialogFooter></>
    }  
    </HelpDialogBody>
  </> : <div/>
}
