export function getQuatity(quantity){
    if(!quantity || quantity <=0){
        return 1;
    }else{
        return quantity;
    }
}

export function validateOrderSelection(item){   
    let modifierGroup = item.modifierGroup;
    if(modifierGroup && modifierGroup.modifiers){
        
      for (let modifier of modifierGroup.modifiers) {
        if(modifier.options){
          let min = modifier.min;
          let rMin = 0;
          for(let option of modifier.options){
            if(option.checked){
              rMin++;
            }
          }
          
          if(min && min >0 &&  rMin <min){ 
            alert('Please select minimum '+ min+' from '+modifier.label);
            return false;
          }
        }
        
      
      }
    }
    return true;
  }

export function getModifierPrice(item){
    let modifierGroup = item.modifierGroup;
    let modifierPrice = 0;
    if(modifierGroup && modifierGroup.modifiers){
        for(let modifier of modifierGroup.modifiers){
          if(modifier.options){
            for(let option of modifier.options){
              let quantity = option.quantity;
              if(!quantity || quantity <=0){
                  quantity = 1;
              }
              
              if(option.checked){
                modifierPrice += (option.price * quantity);
              }
            }
          }
          
        }
       
    }
    //Do not add base price again getPriceWithToppings already adding base price
    return modifierPrice;
}
export function getModifierStr(item){
    let modifierGroup = item.modifierGroup;
    let modifierStr = "";
    if(modifierGroup && modifierGroup.modifiers){
      for (let modifier of modifierGroup.modifiers) {
        if(modifier.options){
          for(let option of modifier.options){
            if(option.checked){
              if(modifierStr !== ""){
                  modifierStr+=",";
              }
              modifierStr = modifierStr+option.label;
            }
          }
        }
        
      }
    }
    return modifierStr;
  }