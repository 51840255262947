import styled from "styled-components";
import { Title } from "../Styles/title";

export const FoodGrid = styled.div`
  @media(max-width: 720px) {
    grid-template-columns: 1fr;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding-bottom: 20px;
`;

  // ${props => props.img !== undefined && `
  //   position: absolute;
  //   justify-content: space-between;
  // `}

export const FoodLabel = styled(Title)`
  ${props => props.img !== undefined ? 
    `position: absolute;` :
    `justify-content: space-between;   display: flex;`
  }
  
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
`;

//  height: ${props => props.img === undefined ? '40px' : '100px'}
export const Food = styled.div`
  ${props => props.img !== undefined && `
  height : 100px;
  `}
 padding: 5px; 
 font-size: 20px; 
 background-image: ${({ img }) => `url(${img});`} 
 background-position: center;
 background-size: cover;
 filter: contrast(75%); 
 border-radius: 7px; 
 margin-top: 5px; 
 transition-property: box-shadow margin-top filter; 
 transition-duration: .1s; 
 box-shadow: 0px 0px 2px 0px grey;
 &:hover {
  cursor: pointer; 
  filter: contrast(100%); 
  margin-top: 0px; 
  margin-bottom: 5px; 
  box-shadow: 0px 5px 10px 0px grey;
 }
`;
