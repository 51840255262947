import React from "react";
import styled from "styled-components";
//import { foods } from "../Data/FoodData";
import { formatPrice,isDiscountedPrice } from "../../Data/FoodData";
import Collapsible from "../Collapsible";

//import Collapsible from 'react-collapsible';

//  margin: 0px 400px 50px 20px;
//  height: 1000px;

const DialogContent = styled.div`
  overflow: auto;
  min-height: 20px;
  //padding: 0px 40px;
  //padding-bottom: 20px;
`;

const StrikePrice =styled.span`
  font-size: .875rem;
  color: red;
  margin-left:5px;
  text-decoration: line-through;
  `;
const MenuContent = styled(DialogContent)`
  //padding: 20px;
  height: 100%;
`;

const FoodGrid = styled.div`
  @media(max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  @media(max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 20px;
`;


const FoodLabel = styled.div`
  display: grid;
  grid-gap: 10px;

  ${props => props.img !== undefined ? 
    `grid-template-columns: auto 94px;` :
    `grid-template-columns: auto;`
  }
`;
  
const FoodContainer = styled.div`
  @media(max-width: 500px) {
    padding: 7px 0px;
  }
  padding: 12px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${props => props.oos ? "#c0392b11" : "white" }

    &:hover {
      cursor: pointer;
      background-color: ${props => props.oos ? "#c0392b11" : "#f8f8f8" };
    }
`;

const Description = styled.div`
  color: gray;
  font-size: 13px;
  margin-top: 5px;
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  padding-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImageDetails = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

export default function MenuImageRightTemplate({setOpenFood, menu, showCount }) {
  
  return (
    <MenuContent>
      {menu && Object.entries(menu).map(([sidx, section]) => (
        section ?
        <Collapsible key={section.id} trigger={section.name + (showCount? ' ('+Object.entries(section.foods).length+')' : '')} open={sidx === "0"}>
          <FoodGrid>
            {section.foods && Object.entries(section.foods).map(([fidx, food]) => (
            food ?
            <FoodContainer key={section.id+food.id} oos={food.oos}
              
              onClick={() => {food.oos ? alert(food.name + " is temporarily out of stock") :
                //document.body.className ="disable-scroll";
                setOpenFood(food);     
              }}
            >
              <FoodLabel img={food.img}>                  
              <ImageDetails>
                  <Title>
                    <span opacity="0.5"><b>{food.name} </b></span>
                    {food.oos ? <span style={{backgroundColor: "#e74c3c", color: "#FFFFFF", borderRadius: "5px"}}><b>&nbsp;Sold Out&nbsp;</b></span> : ""}
                  </Title>
                  <Description>{food.desc}</Description>
                  <div style={{paddingLeft:"10px",paddingTop:"5px", fontSize:"15px"}}>{food.pricelabel? food.pricelabel: formatPrice(food.price/100) }{isDiscountedPrice(food.original_price,food.price) &&<StrikePrice>{formatPrice(food.original_price/100)}</StrikePrice>}</div>
                </ImageDetails>

                {food.img ? <img src={food.img} alt={food.name} style={{"width": "94px", "height": "94px", "objectFit": "cover", "borderRadius": "5%"}} /> : <div></div>} 

              </FoodLabel>
              {/* <Description>{food.desc}</Description> */}
            </FoodContainer> : <div key={sidx+ ' ' + fidx}/>
          ))}
        </FoodGrid>
      </Collapsible> : <div key={sidx}/>
    ))}
    </MenuContent>
  );
}
