import React from 'react';
import styled from "styled-components";


//  ${({ img }) => (img ? `background-image: url(${img});` : 
//`background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)`)}

// ${props => props.img !== undefined ? 
//   `background-image: url(${img});` :
//   `background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)`
// }

// background-image: ${props => props.img === undefined ? 'url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)' 
// : `url(${img})`}

export const BannerDiv = styled.div`
  height: 200px;
  background-image: ${({ imgDesktop }) => (imgDesktop ? `url(${imgDesktop});` : `url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/food_smallest.jpg?alt=media&token=84cb58b2-ad4f-4a7c-b83e-c9c03fcfba16);`)};

  @media(max-width: 720px){ 
    background-image: ${({ img }) => (img ? `url(${img});` : `url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/banner_optimized.jpg?alt=media&token=7daa55d0-b3e8-4a3d-8659-170b3cbe089b);`)};
  }  

  background-position: center;
  background-size: cover;
  filter: contrast(75%);
  position: relative;
  top: 42px;
  background-repeat: no-repeat;
`;

export function Banner(props){
  console.log('props',props);
  return <BannerDiv {...props}/>

}